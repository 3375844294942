import { responsiveFontSizes } from '@material-ui/core';
import { Forms } from '@ngt/forms';
import * as Dtos from "./apis/dtos";

declare let global: any;

if (typeof global === 'undefined')
{
    (window as any).global = window;
}

const user = global.FORMS_AUTH as Dtos.UserSingleResponse;
const formMetadata = global.FORM_METADATA as Dtos.GetFormMetadataResponse;

const primaryColor = '#1f3886';
const secondaryColor = '#95c3e9';

const forms = new Forms({
    initialAuthentication: user,
    dtos: Dtos,
    baseRoute: '/melanoma',
    extensions: [
    ],
    formMetadata: formMetadata?.formMetadata,
    themeOptions: {
        beforeThemeCreated: themeOptions =>
        {
            return {
                ...themeOptions,
                palette: {
                    ...themeOptions.palette,
                    primary: {
                        main: primaryColor
                    },
                    secondary: {
                        main: secondaryColor
                    }
                },
                typography: {
                    ...themeOptions.typography,
                    h1: {
                        ...(themeOptions?.typography as any)?.h1,
                        color: secondaryColor
                    },
                    h2: {
                        ...(themeOptions?.typography as any)?.h2,
                        color: primaryColor
                    },
                    h3: {
                        ...(themeOptions?.typography as any)?.h3,
                        color: secondaryColor
                    },
                    h4: {
                        ...(themeOptions?.typography as any)?.h4,
                        color: primaryColor
                    },
                    h5: {
                        ...(themeOptions?.typography as any)?.h5,
                        color: secondaryColor
                    },
                    h6: {
                        ...(themeOptions?.typography as any)?.h6,
                        color: primaryColor
                    }
                }
            };
        },
        afterThemeCreated: theme =>
        {
            return responsiveFontSizes(theme);
        }
    }
});

export default forms;