import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { ContextForm, DatePicker, GroupedField, IConditionParameters, IValidationError, Select, SubmitButton, TextArea, useAuthenticatedUser } from '@ngt/forms';
import { IFormSubmit } from '@ngt/forms-core';
import React, { FunctionComponent, useCallback, useContext, useState, useEffect, useRef } from 'react';
import { generatePath, Redirect, useHistory, useParams } from 'react-router-dom';
import * as Dtos from 'src/apis/dtos';
import BlockNavigation from '../../components/Form/BlockNavigation';
import PatientNavigation from '../../components/Layouts/PatientNavigation';
import PatientContext from '../../contexts/PatientContext';
import { Routes } from '../../shared';
import lookupFilterProps from '../../utilities/lookupFilterProps';
import { UserDto } from 'src/apis/dtos';

interface IPatientProps {

}

const useStyles = makeStyles(theme => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    selectors: {
        padding: theme.spacing(0, 2)
    },
    alert: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    container: {
        padding: theme.spacing(1, 2)
    },
    wrapper: {
        display: 'inline-flex',
        position: 'relative',
        padding: theme.spacing(1)
    }
}));

const Metastases: FunctionComponent<IPatientProps> = () => {
    const classes = useStyles();
    const patientContext = useContext(PatientContext);
    const patient = patientContext.data as Dtos.Patient | undefined;
    const history = useHistory();
    const routeParams = useParams<Record<string, string>>();

    const redirectNewMetastases: IFormSubmit<Dtos.Patient, IValidationError> = useCallback(async (formState, formActions) => {
        if (!routeParams?.formId) {
            history.push(generatePath(Routes.PatientForms, { patientId: patient?.id!, formDefinitionCode: 'metastases' }));
        }
    }, [routeParams, history])

    /* START text area: Try to make text area with dynamic height. */
    const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
    const [textAreaValue, setTextAreaValue] = useState<String>();
    const onTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTextAreaValue(event.target.value);
    };

    useEffect(() => {
        if (textAreaRef && textAreaRef.current) {
            textAreaRef.current.style.height = "0px";
            const scrollHeight = textAreaRef.current.scrollHeight;
            textAreaRef.current.style.height = scrollHeight + "px";
        }
    }, [textAreaValue]);
    /* END text area: Try to make text area with dynamic height. */

    const goBackToList = () => {
        history.push(generatePath(Routes.PatientForms, { patientId: routeParams.patientId, formDefinitionCode: routeParams.formDefinitionCode }));
    }

    const deleteRecord = () => {
        var deleteConfirmation = window.confirm("Do you want to delete this metastasis record?");
        if (deleteConfirmation == true) {
            //console.log('delete? - You pressed OK');
            // add the deletion code here
            let deleteResult: string = '';

            const baseUrl = window.location.origin;
            //console.log('history: ', history);
            //console.log('baseUrl: ', baseUrl);
            //console.log('routeParams: ', routeParams);

            fetch(baseUrl + "/api/record-delete-id/misc/Metastases/" + routeParams.patientId + "/" + routeParams.formRepeat + "?format=json")
                .then(res => res.json())
                .then(
                    (result) => {
                        if (typeof result.deletionResult != 'undefined')
                        {
                            deleteResult = result.deletionResult[0];
                            window.alert(deleteResult);
                        }
                    },
                    (error) => {
                        window.alert(error);
                    }
            )

            history.push(generatePath(Routes.PatientForms, { patientId: routeParams.patientId, formDefinitionCode: routeParams.formDefinitionCode }));

        } else {
            //console.log('delete? - You pressed Cancel');
        }
    }

    const xs = 12;
    const sm = 12;
    const md = 6;
    const lg = 4;
    const xl = 4;

    // Authenticate user
    const { data: authedUser } = useAuthenticatedUser<UserDto>();
    if (!authedUser?.isAdmin && !authedUser?.isStandard) {
        // Redirect to forbidden page
        return <Redirect to={Routes.Forbidden} />
    }

    return (
        <>
            <PatientNavigation patientId={patient?.id} />
            <ContextForm onAfterSubmit={redirectNewMetastases}>
                <BlockNavigation />
                <Grid container>
                    <Grid container item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={classes.container} >
                        <Typography variant="h2" component="h2" gutterBottom>
                            Metastases
                        </Typography>
                    </Grid>
                </Grid>
                <GroupedField name="metastaticDate" component={DatePicker} />
                <GroupedField name="metastaticType" component={Select} LookupFilterProps={lookupFilterProps} />
                <GroupedField name="metastaticSite" component={Select} LookupFilterProps={lookupFilterProps} />
                <GroupedField name="comments" component={TextArea} inputRef={textAreaRef} onChange={onTextAreaChange} />

                <Grid container justify='space-between' >
                    <Grid item >
                        <SubmitButton color="primary" variant="contained">
                            Save
                        </SubmitButton>
                    </Grid>
                    <Grid item >
                        <div className={classes.wrapper}>
                            <Button color="primary" variant="contained" onClick={deleteRecord} >Delete</Button>
                        </div>
                    </Grid>

                    <Grid item container >
                        <div className={classes.wrapper}>
                            <Button color="primary" variant="contained" onClick={goBackToList} >Cancel</Button>
                        </div>
                    </Grid>
                </Grid>

            </ContextForm>
        </>
    )
}

export default Metastases