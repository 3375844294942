import { faPlus, faSearch, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { GridColDef, GridPageChangeParams, GridRowParams } from '@material-ui/data-grid';
import { CollapsibleTable, Form, IFormLabel, InputOnlyField, SubmitButton, Text, useAuthenticatedUser } from '@ngt/forms';
import { IFormContext, IFormSubmit } from '@ngt/forms-core';
import { DateTime } from 'luxon';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { generatePath, Redirect, useHistory } from "react-router-dom";
import { UserDto } from '../../apis/dtos';
import ResetButton from '../../components/Form/ResetButton';
import usePatients from '../../hooks/usePatients';
import { Routes } from '../../shared';


interface IPatientsProps {

}

const useStyles = makeStyles(theme => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    selectors: {
        padding: theme.spacing(0, 2)
    },
    alert: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    container: {
        padding: theme.spacing(1, 2)
    }
}));

interface ISearch {
    pin?: number | null;
    mrn?: string;
    firstName?: string;
    lastName?: string;
}

const initialData: ISearch = {
    pin: '' as any,
    mrn: undefined,
    firstName: undefined,
    lastName: undefined
};

const labels: IFormLabel[] = [
    {
        name: 'mrn',
        label: 'MRN',
        detailedLabel: 'MRN'
    },
    {
        name: 'firstName',
        label: 'First Name',
        detailedLabel: 'First Name'
    },
    {
        name: 'lastName',
        label: 'Last Name',
        detailedLabel: 'Last Name'
    }
];

const Patients: FunctionComponent<IPatientsProps> = () => {
    const classes = useStyles();
    const history = useHistory();

    const [page, setPage] = React.useState<number>(0);
    const [pageSize, setPageSize] = React.useState<number>(20);
    const [mrn, setMrn] = React.useState<string | undefined>('');
    const [firstName, setFirstName] = React.useState<string | undefined>('');
    const [lastName, setLastName] = React.useState<string | undefined>('');

    const { data: patients, total: patientCount, loading: patientsLoading } =
        usePatients(mrn, firstName, lastName, page, pageSize);

    // Add the age on the data object
    //console.log('patients before: ', patients);
    if (patients != undefined) {

        for (let i = 0; i < patients.length; i++) {
            // Calculate age.
            var birthDayStr = patients[i].dateOfBirth !== 'undefined' ?
                        patients[i].dateOfBirth :
                        'undefined';

            var today = new Date();
            var birthDay = new Date(birthDayStr ? birthDayStr : '');
            var age = today.getFullYear() - birthDay.getFullYear();
            var month = today.getMonth() - birthDay.getMonth();
            if (month < 0 || (month === 0 && today.getDate() < birthDay.getDate()))
                age--;            

            patients[i]["age"] = age;
        }
    }
    //console.log('patients after: ', patients);



    const patientColumns: GridColDef[] = useMemo(() => {
        return [
            {
                field: 'mrn',
                headerName: 'MRN',
                width: 120
            },
            {
                field: 'lastName',
                headerName: 'Last Name',
                width: 180
            },
            {
                field: 'firstName',
                headerName: 'First Name',
                width: 180
            },
            {
                field: 'dateOfBirth',
                headerName: 'Date of Birth',
                valueFormatter: params => DateTime.fromISO(params.row.dateOfBirth)?.toFormat('dd MMM yyyy'),
                width: 170
            },
            {
                field: 'age',
                headerName: 'Age',
                width: 180
            }
        ]
    }, []);



    const onPageChange = (value: GridPageChangeParams) => {
        setPage(value.page)
    };

    const onPageSizeChange = (value: GridPageChangeParams) => {
        setPageSize(value.pageSize)
    };

    const onSubmit: IFormSubmit<ISearch, any> = useCallback(async (formState, formActions) => {
        setMrn(formState.values.mrn);
        setFirstName(formState.values.firstName);
        setLastName(formState.values.lastName);
    }, [setMrn, setFirstName, setLastName])

    const onClearClick = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<ISearch, any>) => {
        setMrn(undefined);
        setFirstName(undefined);
        setLastName(undefined);

        return true;
    }

    const onNewClick = () => {
        history.push(Routes.PatientNew);
    }

    const onPatientClick = useCallback((params: GridRowParams) => {
        //console.log('form id: ', params.id);
        history.push(generatePath(Routes.PatientById, { formId: params.id }));
    }, [history]);

    const xs = 12;
    const sm = 12;
    const md = 6;
    const lg = 4;
    const xl = 4;

    // Authenticate user
    const { data: authedUser } = useAuthenticatedUser<UserDto>();
    if (!authedUser?.isAdmin && !authedUser?.isStandard) {
        // Redirect to forbidden page
        return <Redirect to={Routes.Forbidden} />
    }

    return <>
        <style jsx>{`
            div.MuiDataGrid-cell {
                cursor: pointer;
            }
        `}</style>
        <Grid container justifyContent="space-between">
            <Grid container item xs={12} sm={6} md={md} lg={lg} xl={xl} justifyContent="flex-start" className={classes.container}>
                <Typography variant="h2" component="h2" gutterBottom>
                    Patients
                </Typography>
            </Grid>
            <Grid container item xs={12} sm={6} md={md} lg={lg} xl={xl} justifyContent="flex-end" className={classes.container}>
                <Button color="primary" variant="contained" onClick={onNewClick}>
                    <FontAwesomeIcon icon={faPlus} fixedWidth />&nbsp;New Patient
                </Button>
            </Grid>
        </Grid>
        <Form
            initialValues={initialData}
            onSubmit={onSubmit}
            labels={labels}
        >
            <Grid container>
                <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={classes.container}>
                    <InputOnlyField name="mrn" component={Text} />
                </Grid>
                <Grid container item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={classes.container}>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={classes.container}>
                    <InputOnlyField name="firstName" component={Text} />
                </Grid>
                <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={classes.container}>
                    <InputOnlyField name="lastName" component={Text} />
                </Grid>
                <Grid container item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={classes.container}>
                    <Grid container item xs={6} justifyContent="center">
                        <SubmitButton color="primary" variant="outlined">
                            <FontAwesomeIcon icon={faSearch} fixedWidth />&nbsp;Search
                        </SubmitButton>
                    </Grid>
                    <Grid container item xs={6} justifyContent="flex-end">
                        <ResetButton color="primary" variant="outlined" onClick={onClearClick}>
                            <FontAwesomeIcon icon={faTimes} fixedWidth />&nbsp;Clear
                        </ResetButton>
                    </Grid>
                </Grid>
            </Grid>
        </Form>
        <CollapsibleTable
            title="Patients"
            entityName="Patient"
            loading={patientsLoading}
            rows={patients ?? []}
            rowsPerPageOptions={[20, 50, 100]}
            page={page}
            rowCount={patientCount ?? 0}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onRowClick={onPatientClick}
            autoHeight
            columns={patientColumns}
        />
    </>
}

export default Patients