import { BoundMutator, FormMetadata, Forms, FormsContext, IDataSingleResponse, IValidationResponse, IValidationResult } from '@ngt/forms';
import { useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useSWR, { cache, mutate, SWRConfiguration } from 'swr';
import { IPatientForm, Surgery } from '../apis/dtos';
import { URLSearchParams } from "url"


// @ts-ignore: declared but not used 'url'
const fetcher = async <TPatientForm extends IPatientForm = IPatientForm>(url: string,
    forms: Forms,
    formMetadata: FormMetadata,
    patientId: number,
    repeat: number,
    data?: TPatientForm,
    metadata?: Record<string, any>) => {
    if (!patientId || !repeat) {
        return await create<TPatientForm>(forms, formMetadata, data, metadata );
    }

    return await get<TPatientForm>(forms, formMetadata, patientId, repeat)
}

const create = async <TPatientForm extends IPatientForm = IPatientForm >(forms: Forms,
    formMetadata: FormMetadata,
    data?: TPatientForm,
    metadata?: Record<string, any>) => {

    //let params = (new URL(window.location.href)).searchParams;
    //let melCode = params.get("melCode"); 

    //console.log('create patient melCode: ', melCode);
    //console.log('create patient formMetadata.name: ', formMetadata.name);

    const requestType = forms.dtos[`${formMetadata.name}PostCreate`]

    const response: IDataSingleResponse<TPatientForm> = await forms.serviceStackClient.post(new requestType({ data, metadata }));

    //// if surgery form
    //if (formMetadata.name.includes('Surgery') ) {
    //    console.log('create patient metadata: ', metadata);

    //    let params = (new URL(window.location.href)).searchParams;
    //    let melCode = params.get("melCode"); 
    //    console.log('fetcher isSurgery?: ', formMetadata.name.includes('Surgery'));

    //    response.extraData = { melanomaCode: melCode };

    //    console.log('create data: ', data);
    //}
    //console.log('create patient response.extraData: ', response.extraData);


    return response;
}

const get = async <TPatientForm extends IPatientForm = IPatientForm>(forms: Forms,
    formMetadata: FormMetadata,
    patientId: number,
    repeat: number) => {
    const requestType = forms.dtos[`${formMetadata.name}GetSingleByPatientIdAndRepeat`]

    const response: IDataSingleResponse<TPatientForm> = await forms.serviceStackClient.get(new requestType({ patientId, repeat }));

    return response;
}

// @ts-ignore: declared but not used 'url'
const save = async <TPatientForm extends IPatientForm = IPatientForm>(forms: Forms,
    formMetadata: FormMetadata,
    data: TPatientForm,
    metadata: Record<string, any> | undefined) => {
    const requestType = forms.dtos[`${formMetadata.name}PostSave`]

    console.log('save patient requestType: ', requestType);
    const response: IDataSingleResponse<TPatientForm> = await forms.serviceStackClient.post(new requestType({ data, metadata }));
    console.log('save patient response: ', response);

    return response;
}

const validate = async <TPatientForm extends IPatientForm = IPatientForm, TValidationResult extends IValidationResult = IValidationResult>(
    forms: Forms,
    formMetadata: FormMetadata,
    data: TPatientForm, metadata: Record<string, any> | undefined) => {
    const requestType = forms.dtos[`${formMetadata.name}PostValidate`]

    const response: IValidationResponse<TValidationResult> = await forms.serviceStackClient.post(new requestType({ data, metadata }));

    return response;
}

const usePatientFormByPatientIdAndRepeat = <TPatientForm extends IPatientForm = IPatientForm, TValidationResult extends IValidationResult = IValidationResult>(
    formDefinitionIdentifier?: number | string | null,
    patientId?: number | null,
    repeat?: number | null,
    createData?: TPatientForm,
    createMetadata?: Record<string, any>,
    configuration?: SWRConfiguration<IDataSingleResponse<TPatientForm>, IDataSingleResponse<TPatientForm>>) => {
    const forms = useContext(FormsContext);

    const routeParams = useParams<Record<string, string>>();

    const formMetadata = useMemo(() => {
        if (typeof formDefinitionIdentifier === 'number') {
            return forms.formMetadata.find(fm => fm.formDefinitionId === formDefinitionIdentifier);
        }

        return forms.formMetadata.find(fm => fm.formDefinitionCode === formDefinitionIdentifier);
    }, [forms.formMetadata, formDefinitionIdentifier]);

    const cacheKey = useMemo(() => {
        console.log('~~~~~~~~~( START: cacheKey )~~~~~~~~~');
        console.log('forms: ', forms);
        console.log('patientId: ', patientId);
        console.log('repeat: ', repeat);
        console.log('formMetadata: ', formMetadata);
        console.log('createData: ', createData);
        console.log('createMetadata: ', createMetadata);
        
        if (!formMetadata) {
            return null;
        }

        if ((!patientId || !repeat) && !createData && !createMetadata) {
            return null;
        }
        
        if (!patientId || !repeat) {
            return [`${forms.baseRoute}/form/${formMetadata?.formDefinitionCode}/single/patient-id-repeat`, forms, formMetadata, null, null, createData, createMetadata]
        }
        console.log('~~~~~~~~~( END: cacheKey )~~~~~~~~~');

        return [`${forms.baseRoute}/form/${formMetadata?.formDefinitionCode}/single/patient-id-repeat`, forms, formMetadata, patientId, repeat, null, null]
    }, [forms, patientId, repeat, formMetadata, createData, createMetadata])

    const { data, error } = useSWR<IDataSingleResponse< TPatientForm>,  IDataSingleResponse<TPatientForm>>(cacheKey, fetcher, configuration);



    const boundMutate: BoundMutator<IDataSingleResponse<TPatientForm>> = useCallback((newData, shouldRevalidate) => {
        return mutate(cacheKey, newData, shouldRevalidate);
    }, [cacheKey]);

    const boundSave = useCallback(async (saveData: TPatientForm, metadata?: Record<string, any>, shouldRevalidate?: boolean) => {
        if (formMetadata) {

            const response = await save<TPatientForm>(forms, formMetadata, saveData, metadata);

            if (cacheKey) {
                // !!cacheKey[3] = has an id.
                const revalidate = shouldRevalidate ?? !!cacheKey[3];

                const updated = await boundMutate(response, revalidate);

                return updated?.data;
            }

            return response?.data;
        }

        return null;
    }, [boundMutate, forms, formMetadata, cacheKey]);

    const boundValidate = useCallback(async (validateData: TPatientForm, metadata?: Record<string, any>) => {
        if (formMetadata) {
            const response = await validate<TPatientForm, TValidationResult>(forms, formMetadata, validateData, metadata);

            return response?.validationResult;
        }

        return null;
    }, [forms, formMetadata]);

    let params = (new URL(window.location.href)).searchParams;
    let melCode = params.get("melCode");

    const result = useMemo(() => {
        return {
            data: data?.data as TPatientForm | undefined | null,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate,
            save: boundSave,
            validate: boundValidate,
            melCode: melCode as string | null
        };
    }, [boundMutate, data, error, boundValidate, boundSave])

    return result;
}

export default usePatientFormByPatientIdAndRepeat;