/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected form.
 * --------------------------------------------------------------------------------
 */

import { FormContext, FormDefinitionContext, FormDefinitionSingleResponse, FormTypeContext, FormTypeSingleResponse, IDataSingleResponse, IForm, LookupCollectionResponse, LookupsContext, RouteLoading, useFormDataById, useFormDefinitionByCode, useFormTypeById, useLookupsByFormDefinitionId } from '@ngt/forms';
import * as React from 'react';
import { useMemo } from 'react';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import { Configuration } from 'swr/dist/types';
import { IPatientForm } from '../../apis/dtos';
import PatientContext from '../../contexts/PatientContext';
import usePatientFormByPatientIdAndRepeat from '../../hooks/usePatientFormByPatientIdAndRepeat';
import { IPatientFormMapping } from '../../utilities/IPatientFormMapping';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IPatientFormByPatientIdAndRepeatResolverProps {
    formDefinitionCode: string;
    patientId: number | null;
    formRepeat: number | null;
    resolveBeforeLoad?: boolean | null;
    formMapping?: Record<string, IPatientFormMapping>;

    patientConfiguration?: Partial<Configuration<IDataSingleResponse<IForm>, IDataSingleResponse<IForm>>>;
    formDefinitionConfiguration?: Partial<Configuration<FormDefinitionSingleResponse, FormDefinitionSingleResponse>>;
    lookupConfiguration?: Partial<Configuration<LookupCollectionResponse, LookupCollectionResponse>>;
    formConfiguration?: Partial<Configuration<IDataSingleResponse<IPatientForm>, IDataSingleResponse<IPatientForm>>>;
    formTypeConfiguration?: Partial<Configuration<FormTypeSingleResponse, FormTypeSingleResponse>>;
}
/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const PatientFormByPatientIdAndRepeatResolver: React.FunctionComponent<IPatientFormByPatientIdAndRepeatResolverProps> = ({
    formDefinitionCode,
    patientId,
    formRepeat,
    resolveBeforeLoad,
    formMapping,
    children,

    patientConfiguration,
    formConfiguration,
    lookupConfiguration,
    formDefinitionConfiguration,
    formTypeConfiguration,
}) => {

    const formDefinitionContext = useFormDefinitionByCode(formDefinitionCode, formDefinitionConfiguration);

    const formDefCode = useMemo(() => formDefinitionContext?.data?.code ?? '', [formDefinitionContext?.data?.code]);

    const location = useLocation();
    const params = useParams<Record<string, string>>();

    const currentFormMapping = useMemo(() => {
        const mapping = formMapping?.[formDefCode];

        return {
            components: mapping?.components,
            createData: mapping?.createData?.(patientId, location, params),
            createMetadata: mapping?.createMetadata?.(patientId, location, params)
        }
    }, [formDefCode, formMapping, patientId, location, params]);

    const patientContext = useFormDataById('patient', patientId, undefined, undefined, patientConfiguration);



    const formTypeContext = useFormTypeById(formDefinitionContext?.data?.type, formTypeConfiguration);

    const formContext = usePatientFormByPatientIdAndRepeat(formDefinitionCode, patientId, formRepeat, currentFormMapping?.createData, currentFormMapping?.createMetadata, formConfiguration);
    console.log('!!!!!!!!!!!!!! formContext: ', formContext);

    const lookupsContext = useLookupsByFormDefinitionId(formDefinitionContext?.data?.id, lookupConfiguration);

    let conditionalContexts: React.ReactNode =
        !!formMapping && formDefCode && !!formMapping[formDefCode]?.components ?
            formMapping[formDefCode]?.components :
            children;

    const loading =
        (formDefinitionCode && formDefinitionContext?.loading) ||
        (patientId && patientContext?.loading) ||
        (formDefinitionCode && patientId && formContext?.loading) ||
        (formDefinitionContext?.data?.id && lookupsContext?.loading) ||
        (formDefinitionContext?.data?.type && formTypeContext?.loading);

    const hasError = !!(
        !patientId ||
        !formDefinitionCode ||
        formDefinitionContext?.error ||
        patientContext?.error ||
        formContext?.error ||
        lookupsContext?.error ||
        formTypeContext?.error
    );

    if (resolveBeforeLoad && hasError) {
        return <Redirect to="/error" />
    }

    return (
        <PatientContext.Provider value={patientContext}>
            <FormTypeContext.Provider value={formTypeContext}>
                <FormDefinitionContext.Provider value={formDefinitionContext}>
                    <FormContext.Provider value={formContext}>
                        <LookupsContext.Provider value={lookupsContext}>
                            {
                                !resolveBeforeLoad || !loading ?
                                    conditionalContexts :
                                    <RouteLoading />
                            }
                        </LookupsContext.Provider>
                    </FormContext.Provider>
                </FormDefinitionContext.Provider>
            </FormTypeContext.Provider>
        </PatientContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default PatientFormByPatientIdAndRepeatResolver;
