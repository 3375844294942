import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { GridColDef, GridRowParams } from '@material-ui/data-grid';
import { CollapsibleTable, FormDefinitionContext, GetFieldLookupItem, LookupsContext, useAuthenticatedUser } from '@ngt/forms';
import { DateTime } from 'luxon';
import React, { FunctionComponent, useCallback, useContext, useMemo, useState } from 'react';
import { generatePath, Redirect, useHistory } from "react-router-dom";
import { FormDefinition, Patient, UserDto } from '../../apis/dtos';
import PatientNavigation from '../../components/Layouts/PatientNavigation';
import PatientContext from '../../contexts/PatientContext';
import PatientFormsContext from '../../contexts/PatientFormsContext';
import { Routes } from '../../shared';


interface IPatientFormsProps {
    title: string;
    newButtonTitle: string;
}

const useStyles = makeStyles(theme => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    selectors: {
        padding: theme.spacing(0, 2)
    },
    alert: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    container: {
        padding: theme.spacing(1, 2)
    }
}));


const PatientForms: FunctionComponent<IPatientFormsProps> = ({ title, newButtonTitle }) => {
    const classes = useStyles();
    const history = useHistory();
    const patientContext = useContext(PatientContext);
    const patient = patientContext.data as Patient | undefined;
    const formDefinitionContext = useContext(FormDefinitionContext)
    const formDefinition = formDefinitionContext.data as FormDefinition | undefined
    const { data: patientForms, loading: patientFormsLoading } =
        useContext(PatientFormsContext);

    const onNewClick = () => {
        history.push(generatePath(Routes.PatientFormNew, { patientId: patient?.id!, formDefinitionCode: formDefinition?.code! }));
    }

    const onRowClick = useCallback((params: GridRowParams) => {
        //console.log('history: ', history);
        //console.log('params: ', params);
        //console.log('formDefinitionContext: ', formDefinitionContext);
        //console.log('formDefinition: ', formDefinition);
        history.push(generatePath(Routes.PatientForm, { patientId: patient?.id!, formDefinitionCode: formDefinition?.code!, formRepeat: params.row.repeat }));
    }, [history]);
        
    const xs = 12;
    const sm = 12;
    const md = 6;
    const lg = 4;
    const xl = 4;

    const lookupsContext = useContext(LookupsContext);

    const columns: GridColDef[] = useMemo(() => {
        switch (formDefinition?.code) {
            case 'primary-melanoma':
                return [
                    {
                        field: 'dateOfReferral',
                        headerName: 'Date of Referral',
                        valueFormatter: params => DateTime.fromISO(params.row.dateOfReferral)?.toFormat('dd MMM yyyy'),
                        width: 200
                    },
                    {
                        field: 'primarySite',
                        headerName: 'Primary Site',
                        valueFormatter: params => GetFieldLookupItem(lookupsContext.data, 'PrimarySite', params.row.primarySite)?.value,
                        width: 200
                    },
                    {
                        field: 'breslow',
                        headerName: 'Breslow (mm)',
                        width: 180
                    },
                    {
                        field: 'ulcerationMm',
                        headerName: 'Ulceration (mm)',
                        width: 180
                    }
                ];
                break;
            case 'surgery':
                return [
                    {
                        field: 'surgeryDate',
                        headerName: 'Surgery Date',
                        valueFormatter: params => DateTime.fromISO(params.row.surgeryDate)?.toFormat('dd MMM yyyy'),
                        width: 200
                    },
                    {
                        field: 'surgeryType',
                        headerName: 'Surgery Type',
                        valueFormatter: params => GetFieldLookupItem(lookupsContext.data, 'SurgeryType', params.row.surgeryType)?.value,
                        width: 200
                    },
                    {
                        field: 'comments',
                        headerName: 'Comments',
                        width: 200
                    }
                ];
                break;
            case 'metastases':
                return [
                    {
                        field: 'metastaticDate',
                        headerName: 'Metastatic Date',
                        valueFormatter: params => DateTime.fromISO(params.row.metastaticDate)?.toFormat('dd MMM yyyy'),
                        width: 200
                    },
                    {
                        field: 'metastaticType',
                        headerName: 'Metastatic Type',
                        valueFormatter: params => GetFieldLookupItem(lookupsContext.data, 'MetastasesType', params.row.metastasesType)?.value,
                        width: 200
                    },
                    {
                        field: 'metastaticSite',
                        headerName: 'Metastatic Site',
                        valueFormatter: params => GetFieldLookupItem(lookupsContext.data, 'MetastasesSite', params.row.metastasesSite)?.value,
                        width: 200
                    },
                    {
                        field: 'comments',
                        headerName: 'Comments',
                        width: 200
                    }
                ];
                break;
            case 'treatment':
                return [
                    {
                        field: 'treatmentType',
                        headerName: 'Treatment Type',
                        valueFormatter: params => GetFieldLookupItem(lookupsContext.data, 'TreatmentType', params.row.treatmentType)?.value,
                        width: 200
                    },
                    {
                        field: 'comments',
                        headerName: 'Comments',
                        width: 200
                    }
                ];
                break;
            default:
                return [];
        }

    }, [formDefinition?.code]);

    // Authenticate user
    const { data: authedUser } = useAuthenticatedUser<UserDto>();
    if (!authedUser?.isAdmin && !authedUser?.isStandard) {
        // Redirect to forbidden page
        return <Redirect to={Routes.Forbidden} />
    }

    return <>
        <style jsx>{`
            div.MuiDataGrid-cell {
                cursor: pointer;
            }
        `}</style>

        <PatientNavigation patientId={patient?.id!} />
        <Grid container justifyContent="space-between">
            <Grid container item xs={12} sm={6} md={md} lg={lg} xl={xl} justifyContent="flex-start" className={classes.container}>
                <Typography variant="h2" component="h2" gutterBottom>
                    {title}
                </Typography>
            </Grid>
            <Grid container item xs={12} sm={6} md={md} lg={lg} xl={xl} justifyContent="flex-end" className={classes.container}>
                <Button color="primary" variant="contained" onClick={onNewClick}>
                    <FontAwesomeIcon icon={faPlus} fixedWidth />&nbsp;New {newButtonTitle}
                </Button>
            </Grid>
        </Grid>
        <CollapsibleTable
            title={title}
            entityName={title}
            loading={patientFormsLoading}
            rows={patientForms ?? []}
            onRowClick={onRowClick}
            autoHeight
            columns={columns}
        />
    </>
}

export default PatientForms