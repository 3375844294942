import { faCopyright } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles, Typography } from '@material-ui/core';
import { useIsMobile } from '@ngt/forms';
import React, { FunctionComponent } from 'react';

interface IFooterProps
{
}

const useStyles = makeStyles(theme => ({
    footer: {
        padding: theme.spacing(1, 3),
        textAlign: 'center',
        borderTopStyle: 'solid',
        borderTopColor: theme.palette.primary.main.toString(),
        borderTopWidth: theme.spacing(1)
    }
}));

const Footer: FunctionComponent<IFooterProps> = () =>
{
    const classes = useStyles();

    const isMobile = useIsMobile();

    if (isMobile)
    {
        return null;
    }

    return <div className={classes.footer}>
        <Typography variant="caption">
            Copyright <FontAwesomeIcon icon={faCopyright} fixedWidth /> {(new Date().getFullYear())} Little Company of Mary Health Care Ltd
        </Typography>
    </div>
};

export default Footer;