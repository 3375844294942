/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */
/*
 * --------------------------------------------------------------------------------
 * This file contains the Navigation component that is displayed at the bottom of 
 * each view.
 * --------------------------------------------------------------------------------
 */

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { makeStyles } from '@material-ui/core/styles';
import { Location } from 'history';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces / Types
 * ---------------------------------------------------------------------------------
 */
interface IPatientNavigationProps {
    patientId: number | undefined;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */
const useStyles = makeStyles(theme => ({
    tabs: {
        minHeight: '56px'
    },
    tab: {
        width: '100%',
        flex: '1 0 auto'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */
/**
 * This function gets the specified section of the path.
 * @param location The location object in question
 */
const getPathNameSection = (location: Location) => {
    if (location.pathname.includes(NAVIGATION_PRIMARY_MELANOMAS_VALUE)) {
        return NAVIGATION_PRIMARY_MELANOMAS_VALUE;
    }
    else if (location.pathname.includes(NAVIGATION_SURGERY_VALUE)) {
        return NAVIGATION_SURGERY_VALUE;
    }
    else if (location.pathname.includes(NAVIGATION_METASTASES_VALUE)) {
        return NAVIGATION_METASTASES_VALUE;
    }
    else if (location.pathname.includes(NAVIGATION_TREATMENT_VALUE)) {
        return NAVIGATION_TREATMENT_VALUE;
    }
    else if (location.pathname.includes(NAVIGATION_PATIENT_VALUE)) {
        return NAVIGATION_PATIENT_VALUE;
    }
    else {
        return '';
    }
}

const NAVIGATION_PATIENT_VALUE = 'patient';
const NAVIGATION_PRIMARY_MELANOMAS_VALUE = 'primary-melanoma';
const NAVIGATION_SURGERY_VALUE = 'surgery';
const NAVIGATION_METASTASES_VALUE = 'metastases';
const NAVIGATION_TREATMENT_VALUE = 'treatment';

/*
 * ---------------------------------------------------------------------------------
 * Component
 * ---------------------------------------------------------------------------------
 */
const PatientNavigation: React.FunctionComponent<IPatientNavigationProps> = ({
    patientId
}) => {
    // Get current location from react-router state.
    const location = useLocation();
    // Get classnames for styles.
    const classes = useStyles();
    // Set current selected tab value to initial evaluated value.
    const [value, setValue] = useState(() => getPathNameSection(location));
    // Every time location changes reevaluate and store selected tab value.
    useEffect(() => {
        setValue(getPathNameSection(location));
    }, [location]);
    return (
        <BottomNavigation
            showLabels
            value={value}
            className={classes.tabs}
        >
            <BottomNavigationAction
                value={NAVIGATION_PATIENT_VALUE}
                className={classes.tab}
                label="Patient"
                component={Link}
                disabled={patientId === undefined}
                to={`/${NAVIGATION_PATIENT_VALUE}/${patientId}`}
            />
            <BottomNavigationAction
                value={NAVIGATION_PRIMARY_MELANOMAS_VALUE}
                className={classes.tabs}
                label="Primary Melanomas"
                component={Link}
                disabled={patientId === undefined}
                to={`/${NAVIGATION_PATIENT_VALUE}/${patientId}/${NAVIGATION_PRIMARY_MELANOMAS_VALUE}`}
            />
            <BottomNavigationAction
                value={NAVIGATION_SURGERY_VALUE}
                className={classes.tabs}
                label="Surgeries"
                component={Link}
                disabled={patientId === undefined}
                to={`/${NAVIGATION_PATIENT_VALUE}/${patientId}/${NAVIGATION_SURGERY_VALUE}`}
            />
            <BottomNavigationAction
                value={NAVIGATION_METASTASES_VALUE}
                className={classes.tabs}
                label="Metastases"
                component={Link}
                disabled={patientId === undefined}
                to={`/${NAVIGATION_PATIENT_VALUE}/${patientId}/${NAVIGATION_METASTASES_VALUE}`}
            />
            <BottomNavigationAction
                value={NAVIGATION_TREATMENT_VALUE}
                className={classes.tabs}
                label="Treatments"
                component={Link}
                disabled={patientId === undefined}
                to={`/${NAVIGATION_PATIENT_VALUE}/${patientId}/${NAVIGATION_TREATMENT_VALUE}`}
            />
        </BottomNavigation>
    );
};
/*
 * ---------------------------------------------------------------------------------
 * Export - Default
 * ---------------------------------------------------------------------------------
 */
export default PatientNavigation;