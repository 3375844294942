import { faArrowDown, faArrowUp, faPlus, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, Grid, List, ListItem, makeStyles, Typography } from '@material-ui/core';
import { Checkbox, Condition, ContextForm, FieldProvider, FormContext, IConditionParameters, InputOnlyField, Numeric, SubmitButton, Text, useAuthenticatedUser } from '@ngt/forms';
import { ConditionMode, useFieldActions, useFieldState } from '@ngt/forms-core';
import { get } from 'lodash-es';
import React, { FunctionComponent, useCallback, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { LookupData, LookupType, UserDto } from '../../apis/dtos';
import { Routes } from '../../shared';

interface ILookupTypeProps
{

}

const useStyles = makeStyles(theme => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    selectors: {
        padding: theme.spacing(0, 2)
    },
    alert: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    container: {
        padding: theme.spacing(1, 2)
    }
}));

interface ILookupItemsProps
{
    name: string;
}

interface ILookupItemProps
{
    index: number;
    onDelete: (index: number) => void;
    onUp: (index: number) => void;
    onDown: (index: number) => void;
}

const LookupItems: FunctionComponent<ILookupItemsProps> = ({
    name
}) =>
{
    const classes = useStyles();

    const xs = 12;
    const sm = 12;
    const md = 6;
    const lg = 4;
    const xl = 4;

    return (
        <>
            <Grid container>
                <Grid container item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={classes.container}>
                    <Typography variant="h2" component="h2" gutterBottom>
                        Lookup Items
                    </Typography>
                </Grid>
            </Grid>
            <FieldProvider name={name}>
                <LookupItemsInternal />
            </FieldProvider>
        </>
    );
}

const lookupItemsSubscription = { value: true };


const LookupItemsInternal: FunctionComponent = ({ }) =>
{
    const { value } = useFieldState<LookupData[]>(lookupItemsSubscription);
    const { setValue, getValue } = useFieldActions<LookupData[]>();

    const onAdd = useCallback(() =>
    {
        const currentValue = getValue() ?? [];

        setValue([...currentValue, new LookupData({ repeat: currentValue.length + 1, enabled: true })])
    }, [getValue, setValue]);


    const onDelete = useCallback((index: number) =>
    {
        const currentValue = getValue() ?? [];

        setValue(currentValue.filter((x, i) => i !== index));
    }, [getValue, setValue]);


    const onUp = useCallback((index: number) =>
    {
        const currentValue = [...(getValue() ?? [])];


        if (index == 0)
        {
            return
        }

        const newIndex = index - 1;

        const temp = currentValue[index];
        currentValue[index] = currentValue[newIndex];
        currentValue[newIndex] = temp;

        setValue(currentValue);
    }, [getValue, setValue]);


    const onDown = useCallback((index: number) =>
    {
        const currentValue = [...(getValue() ?? [])];

        if (index == currentValue.length - 1)
        {
            return
        }

        const newIndex = index + 1;

        const temp = currentValue[index];
        currentValue[index] = currentValue[newIndex];
        currentValue[newIndex] = temp;


        setValue(currentValue);
    }, [getValue, setValue]);

    return (
        <>
            <List>
                {value?.map((x, i) =>
                {
                    return (
                        <FieldProvider name={`[${i}]`} key={x.repeat}>
                            <LookupItem
                                index={i}
                                onDelete={onDelete}
                                onUp={onUp}
                                onDown={onDown}
                            />
                            <Divider />
                        </FieldProvider>
                    )
                })}
            </List>
            <Button color="primary" variant="contained" onClick={onAdd}>
                <FontAwesomeIcon icon={faPlus} fixedWidth />&nbsp;Add
            </Button>
        </>
    );
}

type ICondition<T extends object = any> = (params: IConditionParameters<T>) => boolean;

const isLookupItemNew: ICondition<LookupType> = ({ formState: { values } = {}, parentFieldName }) =>
{
    if (!parentFieldName)
    {
        return true;
    }

    const lookupItem: LookupData = get(values, parentFieldName);

    return !lookupItem.id;
};

const isLookupItemExisting: ICondition<LookupType> = ({ formState: { values } = {}, parentFieldName }) =>
{
    if (!parentFieldName)
    {
        return false;
    }

    const lookupItem: LookupData = get(values, parentFieldName);

    return !!lookupItem.id;
};

const conditionSubscription = { values: true };
const conditionNotMet = () => { };

const LookupItem: FunctionComponent<ILookupItemProps> = ({
    index,
    onDelete,
    onUp,
    onDown
}) =>
{
    const onDeleteClick = useCallback(() =>
    {
        onDelete(index);
    }, [onDelete, index])

    const onUpClick = useCallback(() =>
    {
        onUp(index);
    }, [onUp, index])

    const onDownClick = useCallback(() =>
    {
        onDown(index);
    }, [onDown, index])

    return (
        <ListItem>
            <Condition
                type={LookupType}
                condition={isLookupItemNew}
                mode={ConditionMode.Enable}
                subscription={conditionSubscription}
                onConditionNotMet={conditionNotMet}
            >
                <InputOnlyField name="value" component={Text} />
            </Condition>
            <Condition
                type={LookupType}
                condition={isLookupItemExisting}
                mode={ConditionMode.Show}
                subscription={conditionSubscription}
                onConditionNotMet={conditionNotMet}
            >
                <div>
                    <InputOnlyField name="enabled" component={Checkbox} />
                </div>
            </Condition>
            <Condition
                type={LookupType}
                condition={isLookupItemNew}
                mode={ConditionMode.Show}
                subscription={conditionSubscription}
                onConditionNotMet={conditionNotMet}
            >

                <Button color="primary" variant="contained" onClick={onDeleteClick}>
                    <FontAwesomeIcon icon={faTimes} fixedWidth />&nbsp;Delete
                </Button>
            </Condition>
            <Button color="primary" variant="contained" onClick={onUpClick}>
                <FontAwesomeIcon icon={faArrowUp} fixedWidth />&nbsp;Up
            </Button>
            <Button color="primary" variant="contained" onClick={onDownClick}>
                <FontAwesomeIcon icon={faArrowDown} fixedWidth />&nbsp;Down
            </Button>
        </ListItem>
    );
};

const LookupTypeForm: FunctionComponent<ILookupTypeProps> = () =>
{
    const classes = useStyles();
    const formContext = useContext(FormContext);

    const xs = 12;
    const sm = 12;
    const md = 6;
    const lg = 4;
    const xl = 4;

    // Authenticate user
    const { data: authedUser } = useAuthenticatedUser<UserDto>();
    if (!authedUser?.isAdmin && !authedUser?.isStandard) {
        // Redirect to forbidden page
        return <Redirect to={Routes.Forbidden} />
    }

    return (
        <>
            <ContextForm>
                <Grid container>
                    <Grid container item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={classes.container}>
                        <Typography variant="h2" component="h2" gutterBottom>
                            Lookup Type
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={classes.container}>
                        <InputOnlyField name="id" component={Numeric} disabled />
                    </Grid>
                    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={classes.container}>
                        <InputOnlyField name="code" component={Text} disabled />
                    </Grid>
                    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={classes.container}>
                        <InputOnlyField name="name" component={Text} disabled />
                    </Grid>
                </Grid>
                <LookupItems name="items" />
                <Grid container justifyContent="flex-end">
                    <SubmitButton color="primary" variant="contained">
                        Save
                    </SubmitButton>
                </Grid>
            </ContextForm>
        </>
    )
}

export default LookupTypeForm;