import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faBars, faSignOut } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Button, Container, Divider, Grid, IconButton, Link, List, ListItem, ListItemIcon, ListItemText, makeStyles, Paper, SwipeableDrawer, Toolbar, Typography } from '@material-ui/core';
import { useAuthenticatedUser, useIsMobile } from '@ngt/forms';
import React, { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import * as Dtos from 'src/apis/dtos';
import { classNames } from '../../shared';

export interface IMenuItem
{
    icon: IconDefinition;
    label: React.ReactNode;
    url: string;
    external: boolean;
    newTab: boolean;
}

export interface IHeaderProps
{
    menuItems?: IMenuItem[];
}

export interface IHeaderInnerProps
{
    menuItems?: IMenuItem[];
    user?: Dtos.UserDto | null;
}

export interface IHeaderProps
{
    /**
     * Menu Items to display in the header 
     */
    menuItems?: IMenuItem[];
    user?: Dtos.UserDto | null;
}

const useMobileStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    navLinkActive: {
        background: theme.palette.grey[200]
    },
    infoContainer: {
        padding: theme.spacing(3)
    },
    logo: {
        maxWidth: 240
    }
}));

const useDesktopStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    logo: {
        maxWidth: '100%',
        height: 'auto'
    },
    rightColumn: {
        textAlign: 'right',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: 'bold'
    },
    displayName: {
        fontWeight: 'bold'
    },
    navigationBar: {
        minHeight: 5
    },
    navLink: {
        borderRadius: 0,
        textTransform: 'none',
        fontWeight: 'normal'
    },
    navLinkActive: {
        background: theme.palette.primary.light
    },
    border: {
        borderBottomColor: theme.palette.primary.main,
        borderBottomWidth: theme.spacing(1),
        borderBottomStyle: 'solid'
    }
}));

const MobileHeader: React.FunctionComponent<IHeaderInnerProps> = ({
    children,
    menuItems,
    user
}) =>
{
    const classes = useMobileStyles();

    const [open, setOpen] = React.useState(false);

    const onOpen = React.useCallback(() =>
    {
        setOpen(true);
    }, [setOpen])

    const onClose = React.useCallback(() =>
    {
        setOpen(false);
    }, [setOpen])

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        onClick={onOpen}
                    >
                        <FontAwesomeIcon fixedWidth icon={faBars} />
                    </IconButton>
                    <Typography
                        variant="h6"
                        className={classes.title}
                        variantMapping={{
                            h6: 'span'
                        }}
                    >
                        Melanoma Patients
                    </Typography>
                </Toolbar>
            </AppBar>
            <SwipeableDrawer
                open={open}
                onClose={onClose}
                onOpen={onOpen}
            >
                <div
                    className={classes.infoContainer}
                >
                    {
                        <img
                            className={classes.logo}
                            src="/logo/calvary-logo.svg"
                            alt="Calvary Mater"
                            style={{ maxWidth: "45%" }}
                        />
                    }
                </div>
                <Divider />
                <List
                    disablePadding
                >
                    {
                        !!menuItems && menuItems.map((menuItem, i) =>
                        {

                            if (menuItem.external || menuItem.newTab)
                            {
                                return (
                                    <React.Fragment
                                        key={i}
                                    >
                                        <ListItem
                                            component="a"
                                            button
                                            href={menuItem.url}
                                            color="inherit"
                                            target={menuItem.newTab ? "_blank" : undefined}
                                        //className={classes.navLink}
                                        >
                                            <ListItemIcon>
                                                <FontAwesomeIcon icon={menuItem.icon} fixedWidth />
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={menuItem.label}
                                            />
                                        </ListItem>
                                        <Divider />
                                    </React.Fragment>
                                );
                            }

                            return (
                                <React.Fragment
                                    key={i}
                                >
                                    <ListItem
                                        component={NavLink}
                                        button
                                        to={menuItem.url}
                                        color="inherit"
                                        //className={classes.navLink}
                                        activeClassName={classes.navLinkActive}
                                    >
                                        <ListItemIcon>
                                            <FontAwesomeIcon icon={menuItem.icon} fixedWidth />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={menuItem.label}
                                        />
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            );
                        })
                    }
                    {/*<ListItem*/}
                    {/*    component="a"*/}
                    {/*    button*/}
                    {/*    href="/auth/logout"*/}
                    {/*    color="inherit"*/}
                    {/*>*/}
                    {/*    <ListItemIcon>*/}
                    {/*        <FontAwesomeIcon icon={faSignOut} fixedWidth />*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText*/}
                    {/*        primary="Logout"*/}
                    {/*    />*/}
                    {/*</ListItem>*/}
                    <Divider />
                </List>
            </SwipeableDrawer>
        </>
    );
}

const DesktopHeader: React.FunctionComponent<IHeaderInnerProps> = ({
    children,
    menuItems,
    user
}) =>
{

    const classes = useDesktopStyles();

    return (
        <AppBar position="static">
            <Paper
                elevation={0}
                square
                className={classNames({ [classes.border]: !menuItems })}
            >
                <Container className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            {
                                <img
                                    className={classes.logo}
                                    src="/logo/calvary-logo.svg"
                                    alt="Calvary Mater"
                                    style={{ maxWidth: "45%" }}
                                />
                            }
                        </Grid>
                        <Grid
                            className={classes.rightColumn}
                            item
                            xs={12}
                            md={8}
                        >
                            <Typography
                                variant="h1"
                                className={classes.title}
                                variantMapping={{
                                    h1: 'span'
                                }}
                            >
                                Melanoma Patients
                            </Typography>
                            <Typography className={classes.displayName}>
                                {user?.displayName}
                            </Typography>
                            {
                                !!user
                                //&& (
                                //    <Link
                                //        component="a"
                                //        href="/auth/logout"
                                //    >
                                //        Logout <FontAwesomeIcon icon={faSignOut} fixedWidth />
                                //    </Link>
                                //)
                            }
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
            {
                !!menuItems && (
                    <Toolbar
                        className={classes.navigationBar}
                    >
                        <Container>
                            {
                                menuItems.map((menuItem, i) =>
                                {

                                    if (menuItem.external || menuItem.newTab)
                                    {
                                        return (
                                            <Button
                                                key={i}
                                                component="a"
                                                href={menuItem.url}
                                                color="inherit"
                                                target={menuItem.newTab ? "_blank" : undefined}
                                                className={classes.navLink}
                                                startIcon={<FontAwesomeIcon icon={menuItem.icon} fixedWidth />}
                                                size="large"
                                            >
                                                {menuItem.label}
                                            </Button>
                                        );
                                    }

                                    return (
                                        <Button
                                            key={i}
                                            component={NavLink}
                                            to={menuItem.url}
                                            color="inherit"
                                            className={classes.navLink}
                                            activeClassName={classes.navLinkActive}
                                            startIcon={<FontAwesomeIcon icon={menuItem.icon} fixedWidth />}
                                            size="large"
                                        >
                                            {menuItem.label}
                                        </Button>
                                    );
                                })
                            }
                        </Container>
                    </Toolbar>
                )
            }
        </AppBar>
    )
};

const Header: FunctionComponent<IHeaderProps> = ({
    menuItems,
    children
}) =>
{
    const { data: user } = useAuthenticatedUser<Dtos.UserDto>();

    const isMobile = useIsMobile();

    if (isMobile)
    {
        return (
            <MobileHeader
                menuItems={menuItems}
                user={user}
            />
        );
    }

    return (
        <DesktopHeader
            menuItems={menuItems}
            user={user}
        />
    );
}

export default Header