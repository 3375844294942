import { faSearch, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { GridColDef, GridPageChangeParams, GridRowParams } from '@material-ui/data-grid';
import { CollapsibleTable, Form, IFormLabel, InputOnlyField, SubmitButton, Text, useAuthenticatedUser } from '@ngt/forms';
import { IFormContext, IFormSubmit } from '@ngt/forms-core';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { generatePath, Redirect, useHistory } from "react-router-dom";
import { UserDto } from '../../apis/dtos';
import ResetButton from '../../components/Form/ResetButton';
import useLookupTypes from '../../hooks/useLookupTypes';
import { Routes } from '../../shared';


interface ILookupTypesProps {

}

const useStyles = makeStyles(theme => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    selectors: {
        padding: theme.spacing(0, 2)
    },
    alert: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    container: {
        padding: theme.spacing(1, 2)
    }
}));

interface ISearch {
    name?: string;
}

const initialData: ISearch = {
    name: undefined
};

const labels: IFormLabel[] = [
    {
        name: 'name',
        label: 'Name',
        detailedLabel: 'Name'
    }
];

const LookupTypes: FunctionComponent<ILookupTypesProps> = () => {
    const classes = useStyles();
    const history = useHistory();

    const [page, setPage] = React.useState<number>(0);
    const [pageSize, setPageSize] = React.useState<number>(20);
    const [name, setName] = React.useState<string | undefined>('');

    const { data: lookupTypes, total: lookupTypeCount, loading: lookupTypesLoading } =
        useLookupTypes(name, page, pageSize);

    const lookupTypesColumns: GridColDef[] = useMemo(() => {
        return [
            {
                field: 'code',
                headerName: 'Code',
                width: 120
            },
            {
                field: 'name',
                headerName: 'Name',
                width: 180
            }
        ]
    }, []);

    const onPageChange = (value: GridPageChangeParams) => {
        setPage(value.page)
    };

    const onPageSizeChange = (value: GridPageChangeParams) => {
        setPageSize(value.pageSize)
    };

    const onSubmit: IFormSubmit<ISearch, any> = useCallback(async (formState, formActions) => {
        setName(formState.values.name);
    }, [setName])

    const onClearClick = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<ISearch, any>) => {
        setName(undefined);

        return true;
    }

    const onLookupTypeClick = useCallback((params: GridRowParams) => {
        history.push(generatePath(Routes.LookupById, { formId: params.id }));
    }, [history]);

    const xs = 12;
    const sm = 12;
    const md = 6;
    const lg = 4;
    const xl = 4;

    // Authenticate user
    const { data: authedUser } = useAuthenticatedUser<UserDto>();
    if (!authedUser?.isAdmin && !authedUser?.isStandard) {
        // Redirect to forbidden page
        return <Redirect to={Routes.Forbidden} />
    }

    return <>
        <style jsx>{`
            div.MuiDataGrid-cell {
                cursor: pointer;
            }
        `}</style>

        <Grid container>
            <Grid container item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={classes.container}>
                <Typography variant="h2" component="h2" gutterBottom>
                    Lookup Types
                </Typography>
            </Grid>
        </Grid>
        <Form
            initialValues={initialData}
            onSubmit={onSubmit}
            labels={labels}
        >
            <Grid container>
                <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={classes.container}>
                    <InputOnlyField name="name" component={Text} />
                </Grid>
                <Grid container item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={classes.container}>
                    <Grid container item xs={6} justifyContent="center">
                        <SubmitButton color="primary" variant="outlined">
                            <FontAwesomeIcon icon={faSearch} fixedWidth />&nbsp;Search
                        </SubmitButton>
                    </Grid>
                    <Grid container item xs={6} justifyContent="flex-end">
                        <ResetButton color="primary" variant="outlined" onClick={onClearClick}>
                            <FontAwesomeIcon icon={faTimes} fixedWidth />&nbsp;Clear
                        </ResetButton>
                    </Grid>
                </Grid>
            </Grid>
        </Form>
        <CollapsibleTable
            title="Lookup Types"
            entityName="Lookup Type"
            loading={lookupTypesLoading}
            rows={lookupTypes ?? []}
            rowsPerPageOptions={[20, 50, 100]}
            page={page}
            rowCount={lookupTypeCount ?? 0}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onRowClick={onLookupTypeClick}
            autoHeight
            columns={lookupTypesColumns}
        />
    </>
}

export default LookupTypes