import { BoundMutator, FormMetadata, Forms, FormsContext, IDataModel, IValidationResult, ResponseStatus } from '@ngt/forms';
import { useCallback, useContext, useMemo } from 'react';
import useSWR, { mutate, SWRConfiguration } from 'swr';
import { IPatientForm } from '../apis/dtos';

export interface IDataCollectionResponse<TData extends IDataModel = IDataModel> {
    data: TData[] | null | undefined,
    responseStatus: ResponseStatus | undefined;
}

// @ts-ignore: declared but not used 'url'
const fetcher = async <TPatientForm extends IPatientForm = IPatientForm>(url: string,
    forms: Forms,
    formMetadata: FormMetadata,
    patientId: number) => {
    const requestType = forms.dtos[`${formMetadata.name}GetCollectionByPatientId`]

    const response: IDataCollectionResponse<TPatientForm> = await forms.serviceStackClient.get(new requestType({ patientId }));

    return response;
}


const usePatientFormsByPatientId = <TPatientForm extends IPatientForm = IPatientForm, TValidationResult extends IValidationResult = IValidationResult>(
    formDefinitionIdentifier?: number | string | null,
    patientId?: number | null,
    configuration?: SWRConfiguration<IDataCollectionResponse<TPatientForm>, IDataCollectionResponse<TPatientForm>>) => {
    const forms = useContext(FormsContext);

    const formMetadata = useMemo(() => {
        if (typeof formDefinitionIdentifier === 'number') {
            return forms.formMetadata.find(fm => fm.formDefinitionId === formDefinitionIdentifier);
        }

        return forms.formMetadata.find(fm => fm.formDefinitionCode === formDefinitionIdentifier);
    }, [forms.formMetadata, formDefinitionIdentifier]);

    const cacheKey = useMemo(() => {
        if (!formMetadata) {
            return null;
        }

        if (!patientId) {
            return null;
        }

        return [`${forms.baseRoute}/form/${formMetadata?.formDefinitionCode}/collection/patient-id`, forms, formMetadata, patientId]
    }, [forms, patientId, formMetadata])

    const { data, error } = useSWR<IDataCollectionResponse<TPatientForm>, IDataCollectionResponse<TPatientForm>>(cacheKey, fetcher, configuration);

    const boundMutate: BoundMutator<IDataCollectionResponse<TPatientForm>> = useCallback((newData, shouldRevalidate) => {
        return mutate(cacheKey, newData, shouldRevalidate);
    }, [cacheKey]);

    const result = useMemo(() => {
        return {
            data: data?.data as TPatientForm[] | undefined | null,
            error: error?.responseStatus,
            loading: data === undefined && error === undefined,
            mutate: boundMutate
        };
    }, [boundMutate, data, error])

    return result;
}

export default usePatientFormsByPatientId;