import { Grid, makeStyles, Typography } from '@material-ui/core';
import { FunctionComponent } from 'react';

interface IForbiddenAccessProps {}

const useStyles = makeStyles(theme => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    selectors: {
        padding: theme.spacing(0, 2)
    },
    alert: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    container: {
        padding: theme.spacing(1, 2)
    }
}));

const ForbiddenAccess: FunctionComponent<IForbiddenAccessProps> = () => {

    const classes = useStyles();

    return <>
        <Grid container>
            <Grid container item justifyContent="flex-start" className={classes.container}>
                <Typography variant="h2" component="h2" gutterBottom>
                    You do not have a role within this application.
                </Typography>
            </Grid>
        </Grid>

        <Grid container>
            <Grid container item justifyContent="flex-start" className={classes.container}>
                <Typography variant="h2" component="h2" gutterBottom>
                    Please contact the Melanoma Unit if you require access.
                </Typography>
            </Grid>
        </Grid>

    </>
}

export default ForbiddenAccess