import { Typography } from "@material-ui/core";
import { IValidationError } from "@ngt/forms";
import { IInputRenderProps } from "@ngt/forms-core";
import { DateTime, Interval } from "luxon";
import React from "react";


type TextFieldsProps = IInputRenderProps<string, IValidationError>

export interface IAgeDisplayProps extends TextFieldsProps {

}


const AgeDisplay: React.FunctionComponent<IAgeDisplayProps> = ({
    inputRender: { state: { value } }
}) => {

    const age = React.useMemo(() => {
        if (!value) {
            return null;
        }

        const date = DateTime.fromISO(value);

        const diff = Interval.fromDateTimes(date, DateTime.now());
        const diffYears = diff.length('years');

        return diffYears;
    }, [value])

    return (
        <Typography>
            {age?.toFixed(0)}
        </Typography>
    );
}

export default AgeDisplay;