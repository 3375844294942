/* Options:
Date: 2022-02-21 13:29:04
Version: 5.120
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44334

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T> {
    createResponse(): T;
}

export interface IReturnVoid {
    createResponse(): void;
}

export interface IGet {
}

export interface IHasSessionId {
    sessionId: string;
}

export interface IHasBearerToken {
    bearerToken: string;
}

export interface IPost {
}

export interface IDelete {
}

export interface IUserGetSingle {
}

export interface ILookupGetCollectionByFormDefinitionId extends IGet {
    formDefinitionId?: number;
}

export interface IConfigurationGetSingleById extends IGet {
    id?: number;
}

export interface IConfigurationGetCollection extends IGet {
    skip?: number;
    take?: number;
}

export interface IConfigurationGetSingleByCode extends IGet {
    code: string;
}

export interface IDataGetSingleById extends IGet {
    id?: number;
}

export interface IDataGetCollection extends IGet {
    skip?: number;
    take?: number;
}

export class LookupData implements IForm {
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public value: string;
    public enabled?: boolean;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<LookupData>) { (Object as any).assign(this, init); }
}

export class LookupType implements IForm {
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public code: string;
    public name: string;
    public editable?: boolean;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    // @Ignore()
    public items: LookupData[];

    public constructor(init?: Partial<LookupType>) { (Object as any).assign(this, init); }
}

export class Metastases implements IPatientForm {
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public metastaticDate?: string;
    public metastaticType?: number;
    public metastaticSite?: number;
    public comments: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Metastases>) { (Object as any).assign(this, init); }
}

export interface IFormGetSingleByPatientIdAndRepeat extends IGet {
    patientId?: number;
    repeat?: number;
}

export interface IFormGetCollectionByPatientId extends IGet {
    patientId?: number;
    skip?: number;
    take?: number;
}

export class Patient implements IForm {
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public mrn: string;
    public firstName: string;
    public lastName: string;
    public gender?: number;
    public dateOfBirth?: string;
    public medicalOfficer1?: number;
    public medicalOfficer2?: number;
    public medicalOfficer3?: number;
    public diseaseType?: number;
    public familyHistory?: boolean;
    public comments: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Patient>) { (Object as any).assign(this, init); }
}

export class PrimaryMelanoma implements IPatientForm {
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public dateOfReferral?: string;
    public dateOfFirstAppointment?: string;
    public primarySite?: number;
    public rightOrLeft?: number;
    public previousMelanomaHistory: string;
    public breslow?: number;
    public mitoses?: number;
    public ulceration?: boolean;
    public ulcerationMm?: number;
    public sentinelNodeBiopsy?: boolean;
    public sentinelNodeBiopsyResult: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public insitu?: boolean;
    public comments: string;

    public constructor(init?: Partial<PrimaryMelanoma>) { (Object as any).assign(this, init); }
}

export class ReportType implements IForm {
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public code: string;
    public name: string;
    public editable?: boolean;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    // @Ignore()
    public items: LookupData[];

    public constructor(init?: Partial<ReportType>) { (Object as any).assign(this, init); }
}

export class Surgery implements IPatientForm {
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public melanomaId?: number;
    public metastasesId?: number;
    public melanomaCode: string;
    public metastasesCode: string;
    public surgeryDate?: string;
    public surgeryType?: number;
    public surgeryType2?: number;
    public surgeryType3?: number;
    public surgeryLocation?: number;
    public comments: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Surgery>) { (Object as any).assign(this, init); }
}

export class Treatment implements IPatientForm {
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public treatmentType?: number;
    public comments: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Treatment>) { (Object as any).assign(this, init); }
}

export class UserRoles implements IForm {
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public username: string;
    public role?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<UserRoles>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError {
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus {
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class ReportTotalAverage {
    public count?: number;
    public countMen?: number;
    public countWomen?: number;
    public averageAge?: number;
    public averageAgeMen?: number;
    public averageAgeWomen?: number;

    public constructor(init?: Partial<ReportTotalAverage>) { (Object as any).assign(this, init); }
}

export class SurgeryRecurrentMelanomaReport {
    public date: string;
    public patientName: string;
    public localRecurrenceGT3CM: string;
    public inTransitRecurrence: string;
    public regionalLns: string;
    public subNearRegionalLns: string;
    public prevLnDissection: string;
    public prevSentiNodeBiopsy: string;
    public skin: string;
    public lns: string;
    public lung: string;
    public brain: string;
    public liver: string;
    public intestine: string;
    public bone: string;
    public other: string;

    public constructor(init?: Partial<SurgeryRecurrentMelanomaReport>) { (Object as any).assign(this, init); }
}

export class UserWithRoleValue {
    public id?: number;
    public username: string;
    public role: string;

    public constructor(init?: Partial<UserWithRoleValue>) { (Object as any).assign(this, init); }
}

export interface IFileUploadTemporary {
    guid: string;
    name: string;
    extension: string;
    fileSize?: number;
    contentType: string;
    image: boolean;
}

export class UserDto implements IUserDto {
    public userId: string;
    public userName: string;
    public displayName: string;
    public sessionId: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public roles: string[];
    public userRoles: number[];
    public isAdmin: boolean;
    public isStandard: boolean;

    public constructor(init?: Partial<UserDto>) { (Object as any).assign(this, init); }
}

export class MelanomaLookupItem {
    public id?: number;
    public value: string;
    public order?: number;
    public enabled?: boolean;

    public constructor(init?: Partial<MelanomaLookupItem>) { (Object as any).assign(this, init); }
}

export class MelanomaLookup {
    public propertyName: string;
    public items: MelanomaLookupItem[];

    public constructor(init?: Partial<MelanomaLookup>) { (Object as any).assign(this, init); }
}

export class FormMetadata {
    public formDefinitionId?: number;
    public formDefinitionCode: string;
    public name: string;
    public propertyName: string;
    public title: string;
    public instructions: string;

    public constructor(init?: Partial<FormMetadata>) { (Object as any).assign(this, init); }
}

export class FormType implements IFormType {
    public id?: number;
    public code: string;
    public name: string;

    public constructor(init?: Partial<FormType>) { (Object as any).assign(this, init); }
}

export enum ValidationErrorType {
    Warning = 1,
    Low = 2,
    Normal = 3,
    High = 4,
    Critical = 5,
}

export class FormDefinitionPropertyErrorMessage implements IFormDefinitionPropertyErrorMessage {
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Forms.Models.Configuration.FormDefinitionProperty)")
    public formDefinitionPropertyId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormDefinitionPropertyErrorMessage>) { (Object as any).assign(this, init); }
}

export class FormDefinitionProperty {
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Forms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public propertyName: string;
    public label: string;
    public detailedLabel: string;
    public errorMessages: FormDefinitionPropertyErrorMessage[];

    public constructor(init?: Partial<FormDefinitionProperty>) { (Object as any).assign(this, init); }
}

export class FormDefinition {
    public id?: number;
    public code: string;
    public type?: number;
    public name: string;
    public instructions: string;
    public patientInstructions: string;
    public properties: FormDefinitionProperty[];
    // @Ignore()
    public subformProperties: { [index: string]: FormDefinitionProperty[]; };

    public constructor(init?: Partial<FormDefinition>) { (Object as any).assign(this, init); }
}

export enum ValidationResultType {
    Valid = 1,
    Low = 2,
    Normal = 3,
    High = 4,
    Critical = 5,
}

export class ValidationError implements IValidationError {
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<ValidationError>) { (Object as any).assign(this, init); }
}

export class ValidationResult {
    public result: ValidationResultType;
    public errors: ValidationError[];
    public metadata: { [index: string]: Object; };

    public constructor(init?: Partial<ValidationResult>) { (Object as any).assign(this, init); }
}

export interface IForm extends IDataModel, IHasParentFormDefinitionId, IHasParentFormId {
    repeat?: number;
}

export interface IDataModel extends IModel, IHasAudit {
}

export interface IModel {
    id?: number;
}

export interface IHasAudit {
    enteredDate?: string;
    enteredBy: string;
    modifiedDate?: string;
    modifiedBy: string;
}

export interface IHasParentFormDefinitionId {
    parentFormDefinitionId?: number;
}

export interface IHasParentFormId {
    parentFormId?: number;
}

export interface IPatientForm extends IForm {
}

export interface IUserDto {
    userId: string;
    userName: string;
    displayName: string;
    sessionId: string;
}

export interface ILookupItem {
    id: Object;
    value: Object;
    order: Object;
}

export interface ILookup {
    propertyName: string;
    items: ILookupItem[];
}

export interface IFormType extends IConfigurationModel, IHasCode {
    name: string;
}

export interface IConfigurationModel extends IModel {
}

export interface IHasCode {
    code: string;
}

export interface IFormDefinitionPropertyErrorMessage extends IConfigurationModel, IHasFormDefinitionPropertyId {
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IFormDefinitionProperty extends IConfigurationModel, IHasFormDefinitionId {
    propertyName: string;
    label: string;
    detailedLabel: string;
    errorMessages: IFormDefinitionPropertyErrorMessage[];
}

export interface IFormDefinition extends IConfigurationModel, IHasCode {
    type?: number;
    name: string;
    instructions: string;
    properties: IFormDefinitionProperty[];
    subformProperties: { [index: string]: IFormDefinitionProperty[]; };
}

export interface IValidationError {
    property: string;
    message: string;
    detailedMessage: string;
    code: string;
    type?: ValidationErrorType;
}

export interface IValidationResult {
    metadata: { [index: string]: Object; };
    result: ValidationResultType;
    errors: IValidationError[];
}

export interface IHasFormDefinitionId {
    formDefinitionId?: number;
}

export interface IHasFormDefinitionPropertyId {
    formDefinitionPropertyId?: number;
}

export class LookupTypeSearchResponse {
    public data: LookupType[];
    public count?: number;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<LookupTypeSearchResponse>) { (Object as any).assign(this, init); }
}

export class PatientSearchResponse {
    public data: Patient[];
    public count?: number;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PatientSearchResponse>) { (Object as any).assign(this, init); }
}

export class PatientGetTotalAndAverageResponse {
    public data: ReportTotalAverage[];
    public all: Patient[];
    public men: Patient[];
    public women: Patient[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PatientGetTotalAndAverageResponse>) { (Object as any).assign(this, init); }
}

export class ReportTypeSearchResponse {
    public data: ReportType[];
    public count?: number;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ReportTypeSearchResponse>) { (Object as any).assign(this, init); }
}

export class SurgeryGetMelanomaThicknessResponse {
    public data: Object[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SurgeryGetMelanomaThicknessResponse>) { (Object as any).assign(this, init); }
}

export class SurgeryGetPrimaryMelanomaDetailsResponse {
    public data: Object[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SurgeryGetPrimaryMelanomaDetailsResponse>) { (Object as any).assign(this, init); }
}

export class SurgeryGetRecurrentMelanomaResponse {
    public data: SurgeryRecurrentMelanomaReport[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SurgeryGetRecurrentMelanomaResponse>) { (Object as any).assign(this, init); }
}

export class SurgeryGetLocationsResponse {
    public data: Object[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SurgeryGetLocationsResponse>) { (Object as any).assign(this, init); }
}

export class TestResponse {
    public data: Surgery[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<TestResponse>) { (Object as any).assign(this, init); }
}

export class RecordDeleteSingleResponse {
    public deletionResult: string[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RecordDeleteSingleResponse>) { (Object as any).assign(this, init); }
}

export class UserSearchResponse {
    public data: UserRoles[];
    public count?: number;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UserSearchResponse>) { (Object as any).assign(this, init); }
}

export class UserWithRoleValueSearchResponse {
    public data: UserWithRoleValue[];
    public count?: number;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UserWithRoleValueSearchResponse>) { (Object as any).assign(this, init); }
}

export class RolesResponse {
    public data: LookupData[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RolesResponse>) { (Object as any).assign(this, init); }
}

export class RolesByRepeatValueResponse {
    public data: LookupData[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RolesByRepeatValueResponse>) { (Object as any).assign(this, init); }
}

export class UserSearchByIdResponse {
    public data: UserRoles[];
    public count?: number;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UserSearchByIdResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken {
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse {
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse {
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

export class UploadResponse {
    public responseStatus: ResponseStatus;
    public upload: IFileUploadTemporary;

    public constructor(init?: Partial<UploadResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserSingleResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved user.
    */
    // @DataMember(Order=2)
    public user: UserDto;

    public constructor(init?: Partial<UserSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupCollectionResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved lookups.
    */
    // @DataMember(Order=2)
    public lookups: MelanomaLookup[];

    public constructor(init?: Partial<LookupCollectionResponse>) { (Object as any).assign(this, init); }
}

export class GetFormMetadataResponse {
    public responseStatus: ResponseStatus;
    public formMetadata: FormMetadata[];

    public constructor(init?: Partial<GetFormMetadataResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormTypeSingleResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form type.
    */
    // @DataMember(Order=2)
    public configuration: FormType;

    public constructor(init?: Partial<FormTypeSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormTypeCollectionResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form types.
    */
    // @DataMember(Order=2)
    public configuration: FormType[];

    public constructor(init?: Partial<FormTypeCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionSingleResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definition.
    */
    // @DataMember(Order=2)
    public configuration: FormDefinition;

    public constructor(init?: Partial<FormDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionCollectionResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definitions.
    */
    // @DataMember(Order=2)
    public configuration: FormDefinition[];

    public constructor(init?: Partial<FormDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupDataSingleResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved lookup data.
    */
    // @DataMember(Order=2)
    public data: LookupData;

    public constructor(init?: Partial<LookupDataSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupDataCollectionResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved lookup datas.
    */
    // @DataMember(Order=2)
    public data: LookupData[];

    public constructor(init?: Partial<LookupDataCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupDataValidationResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<LookupDataValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupTypeSingleResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved lookup type.
    */
    // @DataMember(Order=2)
    public data: LookupType;

    public constructor(init?: Partial<LookupTypeSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupTypeCollectionResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved lookup types.
    */
    // @DataMember(Order=2)
    public data: LookupType[];

    public constructor(init?: Partial<LookupTypeCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupTypeValidationResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<LookupTypeValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MetastasesSingleResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved metastases.
    */
    // @DataMember(Order=2)
    public data: Metastases;

    public constructor(init?: Partial<MetastasesSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MetastasesCollectionResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved metastasess.
    */
    // @DataMember(Order=2)
    public data: Metastases[];

    public constructor(init?: Partial<MetastasesCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MetastasesValidationResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<MetastasesValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientSingleResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient.
    */
    // @DataMember(Order=2)
    public data: Patient;

    public constructor(init?: Partial<PatientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientCollectionResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patients.
    */
    // @DataMember(Order=2)
    public data: Patient[];

    public constructor(init?: Partial<PatientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientValidationResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PatientValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PrimaryMelanomaSingleResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved primary melanoma.
    */
    // @DataMember(Order=2)
    public data: PrimaryMelanoma;

    public constructor(init?: Partial<PrimaryMelanomaSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PrimaryMelanomaCollectionResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved primary melanomas.
    */
    // @DataMember(Order=2)
    public data: PrimaryMelanoma[];

    public constructor(init?: Partial<PrimaryMelanomaCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PrimaryMelanomaValidationResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<PrimaryMelanomaValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ReportTypeSingleResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved report type.
    */
    // @DataMember(Order=2)
    public data: ReportType;

    public constructor(init?: Partial<ReportTypeSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ReportTypeCollectionResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved report types.
    */
    // @DataMember(Order=2)
    public data: ReportType[];

    public constructor(init?: Partial<ReportTypeCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ReportTypeValidationResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<ReportTypeValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgerySingleResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved surgery.
    */
    // @DataMember(Order=2)
    public data: Surgery;

    public constructor(init?: Partial<SurgerySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgeryCollectionResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved surgerys.
    */
    // @DataMember(Order=2)
    public data: Surgery[];

    public constructor(init?: Partial<SurgeryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SurgeryValidationResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<SurgeryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentSingleResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved treatment.
    */
    // @DataMember(Order=2)
    public data: Treatment;

    public constructor(init?: Partial<TreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentCollectionResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved treatments.
    */
    // @DataMember(Order=2)
    public data: Treatment[];

    public constructor(init?: Partial<TreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentValidationResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<TreatmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserRolesSingleResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved user roles.
    */
    // @DataMember(Order=2)
    public data: UserRoles;

    public constructor(init?: Partial<UserRolesSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserRolesCollectionResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved user roless.
    */
    // @DataMember(Order=2)
    public data: UserRoles[];

    public constructor(init?: Partial<UserRolesCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserRolesValidationResponse {
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: ValidationResult;

    public constructor(init?: Partial<UserRolesValidationResponse>) { (Object as any).assign(this, init); }
}

// @Route("/api/lookup-type", "GET")
export class LookupTypeGetCollectionBySearch implements IReturn<LookupTypeSearchResponse>
{
    public name: string;
    public page?: number;
    public pageSize?: number;

    public constructor(init?: Partial<LookupTypeGetCollectionBySearch>) { (Object as any).assign(this, init); }
    public createResponse() { return new LookupTypeSearchResponse(); }
    public getTypeName() { return 'LookupTypeGetCollectionBySearch'; }
}

// @Route("/api/patients", "GET")
export class PatientGetCollectionBySearch implements IReturn<PatientSearchResponse>
{
    public id?: number;
    public mrn: string;
    public firstName: string;
    public lastName: string;
    public dateOfBirth?: string;
    public page?: number;
    public pageSize?: number;

    public constructor(init?: Partial<PatientGetCollectionBySearch>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientSearchResponse(); }
    public getTypeName() { return 'PatientGetCollectionBySearch'; }
}

// @Route("/api/patients/total-and-average", "GET")
export class PatientGetTotalAndAverage implements IReturn<PatientGetTotalAndAverageResponse>
{
    public id?: number;
    public mrn: string;
    public firstName: string;
    public lastName: string;
    public gender?: number;
    public dateOfBirth?: string;
    public page?: number;
    public pageSize?: number;
    public enteredDate?: string;

    public constructor(init?: Partial<PatientGetTotalAndAverage>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientGetTotalAndAverageResponse(); }
    public getTypeName() { return 'PatientGetTotalAndAverage'; }
}

// @Route("/api/report-type", "GET")
export class ReportTypeGetCollectionBySearch implements IReturn<ReportTypeSearchResponse>
{
    public name: string;
    public page?: number;
    public pageSize?: number;

    public constructor(init?: Partial<ReportTypeGetCollectionBySearch>) { (Object as any).assign(this, init); }
    public createResponse() { return new ReportTypeSearchResponse(); }
    public getTypeName() { return 'ReportTypeGetCollectionBySearch'; }
}

// @Route("/api/report/melanoma-thickness", "GET")
// @Route("/api/report/melanoma-thickness/{byAge}", "GET")
export class SurgeryGetMelanomaThickness implements IReturn<SurgeryGetMelanomaThicknessResponse>
{
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public dateOfReferral?: string;
    public primarySite?: number;
    public rightOrLeft?: number;
    public previousMelanomaHistory: string;
    public breslow?: number;
    public mitoses?: number;
    public ulceration?: boolean;
    public ulcerationMm?: number;
    public sentinelNodeBiopsy?: boolean;
    public sentinelNodeBiopsyResult: string;
    public enteredDate?: string;
    public byAge?: boolean;

    public constructor(init?: Partial<SurgeryGetMelanomaThickness>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryGetMelanomaThicknessResponse(); }
    public getTypeName() { return 'SurgeryGetMelanomaThickness'; }
}

// @Route("/api/report/primary-melanoma-details", "GET")
export class SurgeryGetPrimaryMelanomaDetails implements IReturn<SurgeryGetPrimaryMelanomaDetailsResponse>
{
    public id?: number;
    public dateOfFirstAppointment?: string;

    public constructor(init?: Partial<SurgeryGetPrimaryMelanomaDetails>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryGetPrimaryMelanomaDetailsResponse(); }
    public getTypeName() { return 'SurgeryGetPrimaryMelanomaDetails'; }
}

// @Route("/api/report/recurrent-melanomas", "GET")
export class SurgeryGetRecurrentMelanoma implements IReturn<SurgeryGetRecurrentMelanomaResponse>
{
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public metastaticDate?: string;
    public metastaticType?: number;
    public metastaticSite?: number;
    public comments: string;
    public enteredDate?: string;

    public constructor(init?: Partial<SurgeryGetRecurrentMelanoma>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryGetRecurrentMelanomaResponse(); }
    public getTypeName() { return 'SurgeryGetRecurrentMelanoma'; }
}

// @Route("/api/report/referrals-to-nmu", "GET")
export class SurgeryGetLocations implements IReturn<SurgeryGetLocationsResponse>
{
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public surgeryDate?: string;
    public surgeryType?: number;
    public surgeryLocation?: number;
    public comments: string;
    public enteredDate?: string;

    public constructor(init?: Partial<SurgeryGetLocations>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryGetLocationsResponse(); }
    public getTypeName() { return 'SurgeryGetLocations'; }
}

// @Route("/api/report/test/{Id}", "GET")
export class TestGet implements IReturn<TestResponse>
{
    public id?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public dateOfReferral?: string;
    public primarySite?: number;
    public rightOrLeft?: number;
    public previousMelanomaHistory: string;
    public breslow?: number;
    public mitoses?: number;
    public ulceration?: boolean;
    public ulcerationMm?: number;
    public sentinelNodeBiopsy?: boolean;
    public sentinelNodeBiopsyResult: string;
    public enteredDate?: string;
    public byAge?: boolean;

    public constructor(init?: Partial<TestGet>) { (Object as any).assign(this, init); }
    public createResponse() { return new TestResponse(); }
    public getTypeName() { return 'TestGet'; }
}

// @Route("/api/record-delete-id/patient/{TableName}/{patientId}", "GET")
// @Route("/api/record-delete-id/user/{TableName}/{userRoleId}", "GET")
// @Route("/api/record-delete-id/misc/{TableName}/{patientId}/{recordRepeat}", "GET")
export class RecordDeleteSingle implements IReturn<RecordDeleteSingleResponse>
{
    public tableName: string;
    public patientId: string;
    public userRoleId: string;
    public recordRepeat: string;

    public constructor(init?: Partial<RecordDeleteSingle>) { (Object as any).assign(this, init); }
    public createResponse() { return new RecordDeleteSingleResponse(); }
    public getTypeName() { return 'RecordDeleteSingle'; }
}

// @Route("/api/users", "GET")
export class UserGetCollectionBySearch implements IReturn<UserSearchResponse>
{
    public id?: number;
    public username: string;
    public role?: number;
    public page?: number;
    public pageSize?: number;

    public constructor(init?: Partial<UserGetCollectionBySearch>) { (Object as any).assign(this, init); }
    public createResponse() { return new UserSearchResponse(); }
    public getTypeName() { return 'UserGetCollectionBySearch'; }
}

// @Route("/api/users/withrolevalues", "GET")
export class UserWithRoleValueSearch implements IReturn<UserWithRoleValueSearchResponse>
{
    public id?: number;
    public username: string;
    public role?: number;
    public page?: number;
    public pageSize?: number;

    public constructor(init?: Partial<UserWithRoleValueSearch>) { (Object as any).assign(this, init); }
    public createResponse() { return new UserWithRoleValueSearchResponse(); }
    public getTypeName() { return 'UserWithRoleValueSearch'; }
}

// @Route("/api/users/roles", "GET")
export class Roles implements IReturn<RolesResponse>
{
    public id?: number;
    public value: string;
    public parentFormId?: number;
    public repeat?: number;

    public constructor(init?: Partial<Roles>) { (Object as any).assign(this, init); }
    public createResponse() { return new RolesResponse(); }
    public getTypeName() { return 'Roles'; }
}

// @Route("/api/users/role/{repeat}", "GET")
export class RolesByRepeatValue implements IReturn<RolesByRepeatValueResponse>
{
    public id?: number;
    public value: string;
    public parentFormId?: number;
    public repeat?: number;

    public constructor(init?: Partial<RolesByRepeatValue>) { (Object as any).assign(this, init); }
    public createResponse() { return new RolesByRepeatValueResponse(); }
    public getTypeName() { return 'RolesByRepeatValue'; }
}

// @Route("/api/users/{Id}", "GET")
export class UserGetCollectionByUserId implements IReturn<UserSearchByIdResponse>
{
    public id?: number;
    public username: string;
    public role?: number;

    public constructor(init?: Partial<UserGetCollectionByUserId>) { (Object as any).assign(this, init); }
    public createResponse() { return new UserSearchByIdResponse(); }
    public getTypeName() { return 'UserGetCollectionByUserId'; }
}

// @Route("/auth")
// @Route("/auth/{provider}")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost {
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=16)
    public useTokenCookie?: boolean;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public createResponse() { return new AuthenticateResponse(); }
    public getTypeName() { return 'Authenticate'; }
}

// @Route("/assignroles")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost {
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new AssignRolesResponse(); }
    public getTypeName() { return 'AssignRoles'; }
}

// @Route("/unassignroles")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost {
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public createResponse() { return new UnAssignRolesResponse(); }
    public getTypeName() { return 'UnAssignRoles'; }
}

// @Route("/melanoma/file-upload", "POST")
export class PostUploadFile implements IReturn<UploadResponse>, IPost {

    public constructor(init?: Partial<PostUploadFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new UploadResponse(); }
    public getTypeName() { return 'PostUploadFile'; }
}

// @Route("/melanoma/file-upload/info/{Guid}", "GET")
export class GetUploadInfo implements IReturn<UploadResponse>, IGet {
    public guid: string;

    public constructor(init?: Partial<GetUploadInfo>) { (Object as any).assign(this, init); }
    public createResponse() { return new UploadResponse(); }
    public getTypeName() { return 'GetUploadInfo'; }
}

// @Route("/melanoma/file-upload/{Guid}", "GET")
export class GetUpload implements IReturn<Blob>, IGet {
    public guid: string;

    public constructor(init?: Partial<GetUpload>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetUpload'; }
}

// @Route("/melanoma/file-upload/download/{Guid}", "GET")
export class DownloadUpload implements IReturn<Blob>, IGet {
    public guid: string;

    public constructor(init?: Partial<DownloadUpload>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'DownloadUpload'; }
}

// @Route("/melanoma/file-upload/{Guid}", "DELETE")
export class DeleteUpload implements IReturn<UploadResponse>, IDelete {
    public guid: string;

    public constructor(init?: Partial<DeleteUpload>) { (Object as any).assign(this, init); }
    public createResponse() { return new UploadResponse(); }
    public getTypeName() { return 'DeleteUpload'; }
}

/**
* User - UserDto
*/
// @Route("/melanoma/user/single/user", "GET")
// @Api(Description="User - UserDto")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.User.UserSingleResponse)", StatusCode=500)
// @DataContract
export class UserGetSingle implements IReturn<UserSingleResponse>, IUserGetSingle {

    public constructor(init?: Partial<UserGetSingle>) { (Object as any).assign(this, init); }
    public createResponse() { return new UserSingleResponse(); }
    public getTypeName() { return 'UserGetSingle'; }
}

/**
* Lookup - MelanomaLookup
*/
// @Route("/melanoma/lookup/collection/form-definition/{FormDefinitionId}", "GET")
// @Api(Description="Lookup - MelanomaLookup")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Lookup.LookupCollectionResponse)", StatusCode=500)
// @DataContract
export class LookupGetCollectionByFormDefinitionId implements IReturn<LookupCollectionResponse>, ILookupGetCollectionByFormDefinitionId {
    /**
    * The ID of the Form Definition associated with the lookup to retrieve.
    */
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    public constructor(init?: Partial<LookupGetCollectionByFormDefinitionId>) { (Object as any).assign(this, init); }
    public createResponse() { return new LookupCollectionResponse(); }
    public getTypeName() { return 'LookupGetCollectionByFormDefinitionId'; }
}

// @Route("/melanoma/form-names", "GET")
export class GetFormMetadata implements IReturn<GetFormMetadataResponse>
{

    public constructor(init?: Partial<GetFormMetadata>) { (Object as any).assign(this, init); }
    public createResponse() { return new GetFormMetadataResponse(); }
    public getTypeName() { return 'GetFormMetadata'; }
}

/**
* Form Type
*/
// @Route("/melanoma/form-type/single/id/{Id}", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetSingleById implements IReturn<FormTypeSingleResponse>, IConfigurationGetSingleById {
    /**
    * The ID of the form type to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormTypeGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormTypeSingleResponse(); }
    public getTypeName() { return 'FormTypeGetSingleById'; }
}

/**
* Form Type
*/
// @Route("/melanoma/form-type/collection", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeCollectionResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetCollection implements IReturn<FormTypeCollectionResponse>, IConfigurationGetCollection {
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FormTypeGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormTypeCollectionResponse(); }
    public getTypeName() { return 'FormTypeGetCollection'; }
}

/**
* Form Type
*/
// @Route("/melanoma/form-type/single/code/{Code}", "GET")
// @Api(Description="Form Type")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormType.FormTypeSingleResponse)", StatusCode=500)
// @DataContract
export class FormTypeGetSingleByCode implements IReturn<FormTypeSingleResponse>, IConfigurationGetSingleByCode {
    /**
    * The ID of the form type to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormTypeGetSingleByCode>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormTypeSingleResponse(); }
    public getTypeName() { return 'FormTypeGetSingleByCode'; }
}

/**
* Form Definition
*/
// @Route("/melanoma/form-definition/single/id/{Id}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleById implements IReturn<FormDefinitionSingleResponse>, IConfigurationGetSingleById {
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormDefinitionSingleResponse(); }
    public getTypeName() { return 'FormDefinitionGetSingleById'; }
}

/**
* Form Definition
*/
// @Route("/melanoma/form-definition/collection", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetCollection implements IReturn<FormDefinitionCollectionResponse>, IConfigurationGetCollection {
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<FormDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormDefinitionCollectionResponse(); }
    public getTypeName() { return 'FormDefinitionGetCollection'; }
}

/**
* Form Definition
*/
// @Route("/melanoma/form-definition/single/code/{Code}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleByCode implements IReturn<FormDefinitionSingleResponse>, IConfigurationGetSingleByCode {
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public createResponse() { return new FormDefinitionSingleResponse(); }
    public getTypeName() { return 'FormDefinitionGetSingleByCode'; }
}

/**
* Form - LookupData
*/
// @Route("/melanoma/form/lookup-data/single/id/{Id}", "GET")
// @Api(Description="Form - LookupData")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LookupDataSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LookupDataSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LookupDataSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LookupDataSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LookupDataSingleResponse)", StatusCode=500)
// @DataContract
export class LookupDataGetSingleById implements IReturn<LookupDataSingleResponse>, IDataGetSingleById {
    /**
    * The ID of the lookup data to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<LookupDataGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new LookupDataSingleResponse(); }
    public getTypeName() { return 'LookupDataGetSingleById'; }
}

/**
* Form - LookupData
*/
// @Route("/melanoma/form/lookup-data/collection", "GET")
// @Api(Description="Form - LookupData")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LookupDataCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LookupDataCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LookupDataCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LookupDataCollectionResponse)", StatusCode=500)
// @DataContract
export class LookupDataGetCollection implements IReturn<LookupDataCollectionResponse>, IDataGetCollection {
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<LookupDataGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new LookupDataCollectionResponse(); }
    public getTypeName() { return 'LookupDataGetCollection'; }
}

/**
* Form - LookupData
*/
// @Route("/melanoma/form/lookup-data/create", "POST")
// @Api(Description="Form - LookupData")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LookupDataSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LookupDataSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LookupDataSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LookupDataSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LookupDataSingleResponse)", StatusCode=500)
// @DataContract
export class LookupDataPostCreate implements IReturn<LookupDataSingleResponse>
{
    /**
    * The metadata to use when creating the lookup data.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the lookup data.
    */
    // @DataMember(Order=2)
    public data: LookupData;

    public constructor(init?: Partial<LookupDataPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new LookupDataSingleResponse(); }
    public getTypeName() { return 'LookupDataPostCreate'; }
}

/**
* Form - LookupData
*/
// @Route("/melanoma/form/lookup-data/save", "POST")
// @Api(Description="Form - LookupData")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LookupDataSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LookupDataSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LookupDataSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LookupDataSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LookupDataSingleResponse)", StatusCode=500)
// @DataContract
export class LookupDataPostSave implements IReturn<LookupDataSingleResponse>
{
    /**
    * The metadata to use when creating the lookup data.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The lookup data to save.
    */
    // @DataMember(Order=2)
    public data: LookupData;

    public constructor(init?: Partial<LookupDataPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new LookupDataSingleResponse(); }
    public getTypeName() { return 'LookupDataPostSave'; }
}

/**
* Form - LookupData
*/
// @Route("/melanoma/form/lookup-data/validate", "POST")
// @Api(Description="Form - LookupData")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LookupDataValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LookupDataValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LookupDataValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LookupDataValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LookupDataValidationResponse)", StatusCode=500)
// @DataContract
export class LookupDataPostValidate implements IReturn<LookupDataValidationResponse>
{
    /**
    * The metadata to use when validating the lookup data.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The lookup data to validate.
    */
    // @DataMember(Order=2)
    public data: LookupData;

    public constructor(init?: Partial<LookupDataPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new LookupDataValidationResponse(); }
    public getTypeName() { return 'LookupDataPostValidate'; }
}

/**
* Form - LookupType
*/
// @Route("/melanoma/form/lookup-type/single/id/{Id}", "GET")
// @Api(Description="Form - LookupType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LookupTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LookupTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LookupTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LookupTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LookupTypeSingleResponse)", StatusCode=500)
// @DataContract
export class LookupTypeGetSingleById implements IReturn<LookupTypeSingleResponse>, IDataGetSingleById {
    /**
    * The ID of the lookup type to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<LookupTypeGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new LookupTypeSingleResponse(); }
    public getTypeName() { return 'LookupTypeGetSingleById'; }
}

/**
* Form - LookupType
*/
// @Route("/melanoma/form/lookup-type/collection", "GET")
// @Api(Description="Form - LookupType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LookupTypeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LookupTypeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LookupTypeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LookupTypeCollectionResponse)", StatusCode=500)
// @DataContract
export class LookupTypeGetCollection implements IReturn<LookupTypeCollectionResponse>, IDataGetCollection {
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<LookupTypeGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new LookupTypeCollectionResponse(); }
    public getTypeName() { return 'LookupTypeGetCollection'; }
}

/**
* Form - LookupType
*/
// @Route("/melanoma/form/lookup-type/create", "POST")
// @Api(Description="Form - LookupType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LookupTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LookupTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LookupTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LookupTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LookupTypeSingleResponse)", StatusCode=500)
// @DataContract
export class LookupTypePostCreate implements IReturn<LookupTypeSingleResponse>
{
    /**
    * The metadata to use when creating the lookup type.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the lookup type.
    */
    // @DataMember(Order=2)
    public data: LookupType;

    public constructor(init?: Partial<LookupTypePostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new LookupTypeSingleResponse(); }
    public getTypeName() { return 'LookupTypePostCreate'; }
}

/**
* Form - LookupType
*/
// @Route("/melanoma/form/lookup-type/save", "POST")
// @Api(Description="Form - LookupType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LookupTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LookupTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LookupTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LookupTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LookupTypeSingleResponse)", StatusCode=500)
// @DataContract
export class LookupTypePostSave implements IReturn<LookupTypeSingleResponse>
{
    /**
    * The metadata to use when creating the lookup type.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The lookup type to save.
    */
    // @DataMember(Order=2)
    public data: LookupType;

    public constructor(init?: Partial<LookupTypePostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new LookupTypeSingleResponse(); }
    public getTypeName() { return 'LookupTypePostSave'; }
}

/**
* Form - LookupType
*/
// @Route("/melanoma/form/lookup-type/validate", "POST")
// @Api(Description="Form - LookupType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.LookupTypeValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.LookupTypeValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.LookupTypeValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.LookupTypeValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.LookupTypeValidationResponse)", StatusCode=500)
// @DataContract
export class LookupTypePostValidate implements IReturn<LookupTypeValidationResponse>
{
    /**
    * The metadata to use when validating the lookup type.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The lookup type to validate.
    */
    // @DataMember(Order=2)
    public data: LookupType;

    public constructor(init?: Partial<LookupTypePostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new LookupTypeValidationResponse(); }
    public getTypeName() { return 'LookupTypePostValidate'; }
}

/**
* Form - Metastases
*/
// @Route("/melanoma/form/metastases/single/id/{Id}", "GET")
// @Api(Description="Form - Metastases")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MetastasesSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MetastasesSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MetastasesSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MetastasesSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MetastasesSingleResponse)", StatusCode=500)
// @DataContract
export class MetastasesGetSingleById implements IReturn<MetastasesSingleResponse>, IDataGetSingleById {
    /**
    * The ID of the metastases to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MetastasesGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new MetastasesSingleResponse(); }
    public getTypeName() { return 'MetastasesGetSingleById'; }
}

/**
* Form - Metastases
*/
// @Route("/melanoma/form/metastases/collection", "GET")
// @Api(Description="Form - Metastases")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MetastasesCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MetastasesCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MetastasesCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MetastasesCollectionResponse)", StatusCode=500)
// @DataContract
export class MetastasesGetCollection implements IReturn<MetastasesCollectionResponse>, IDataGetCollection {
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<MetastasesGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new MetastasesCollectionResponse(); }
    public getTypeName() { return 'MetastasesGetCollection'; }
}

/**
* Form - Metastases
*/
// @Route("/melanoma/form/metastases/create", "POST")
// @Api(Description="Form - Metastases")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MetastasesSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MetastasesSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MetastasesSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MetastasesSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MetastasesSingleResponse)", StatusCode=500)
// @DataContract
export class MetastasesPostCreate implements IReturn<MetastasesSingleResponse>
{
    /**
    * The metadata to use when creating the metastases.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the metastases.
    */
    // @DataMember(Order=2)
    public data: Metastases;

    public constructor(init?: Partial<MetastasesPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new MetastasesSingleResponse(); }
    public getTypeName() { return 'MetastasesPostCreate'; }
}

/**
* Form - Metastases
*/
// @Route("/melanoma/form/metastases/save", "POST")
// @Api(Description="Form - Metastases")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MetastasesSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MetastasesSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MetastasesSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MetastasesSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MetastasesSingleResponse)", StatusCode=500)
// @DataContract
export class MetastasesPostSave implements IReturn<MetastasesSingleResponse>
{
    /**
    * The metadata to use when creating the metastases.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The metastases to save.
    */
    // @DataMember(Order=2)
    public data: Metastases;

    public constructor(init?: Partial<MetastasesPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new MetastasesSingleResponse(); }
    public getTypeName() { return 'MetastasesPostSave'; }
}

/**
* Form - Metastases
*/
// @Route("/melanoma/form/metastases/validate", "POST")
// @Api(Description="Form - Metastases")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MetastasesValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MetastasesValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MetastasesValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MetastasesValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MetastasesValidationResponse)", StatusCode=500)
// @DataContract
export class MetastasesPostValidate implements IReturn<MetastasesValidationResponse>
{
    /**
    * The metadata to use when validating the metastases.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The metastases to validate.
    */
    // @DataMember(Order=2)
    public data: Metastases;

    public constructor(init?: Partial<MetastasesPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new MetastasesValidationResponse(); }
    public getTypeName() { return 'MetastasesPostValidate'; }
}

/**
* Form - Metastases
*/
// @Route("/melanoma/form/metastases/single/patient-id-repeat/{PatientId}/{Repeat}", "GET")
// @Api(Description="Form - Metastases")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MetastasesSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MetastasesSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MetastasesSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MetastasesSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MetastasesSingleResponse)", StatusCode=500)
// @DataContract
export class MetastasesGetSingleByPatientIdAndRepeat implements IReturn<MetastasesSingleResponse>, IFormGetSingleByPatientIdAndRepeat {
    /**
    * The id of the patient.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The repeat of the metastases to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<MetastasesGetSingleByPatientIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new MetastasesSingleResponse(); }
    public getTypeName() { return 'MetastasesGetSingleByPatientIdAndRepeat'; }
}

/**
* Form - Metastases
*/
// @Route("/melanoma/form/metastases/collection/patient-id/{PatientId}", "GET")
// @Api(Description="Form - Metastases")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.MetastasesCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.MetastasesCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.MetastasesCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.MetastasesCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.MetastasesCollectionResponse)", StatusCode=500)
// @DataContract
export class MetastasesGetCollectionByPatientId implements IReturn<MetastasesCollectionResponse>, IFormGetCollectionByPatientId {
    /**
    * The id of the patient.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * How many items to skip.
    */
    // @DataMember(Order=2)
    public skip?: number;

    /**
    * How many items to return.
    */
    // @DataMember(Order=3)
    public take?: number;

    public constructor(init?: Partial<MetastasesGetCollectionByPatientId>) { (Object as any).assign(this, init); }
    public createResponse() { return new MetastasesCollectionResponse(); }
    public getTypeName() { return 'MetastasesGetCollectionByPatientId'; }
}

/**
* Form - Patient
*/
// @Route("/melanoma/form/patient/single/id/{Id}", "GET")
// @Api(Description="Form - Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleById implements IReturn<PatientSingleResponse>, IDataGetSingleById {
    /**
    * The ID of the patient to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientSingleResponse(); }
    public getTypeName() { return 'PatientGetSingleById'; }
}

/**
* Form - Patient
*/
// @Route("/melanoma/form/patient/collection", "GET")
// @Api(Description="Form - Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollection implements IReturn<PatientCollectionResponse>, IDataGetCollection {
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PatientGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientCollectionResponse(); }
    public getTypeName() { return 'PatientGetCollection'; }
}

/**
* Form - Patient
*/
// @Route("/melanoma/form/patient/create", "POST")
// @Api(Description="Form - Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostCreate implements IReturn<PatientSingleResponse>
{
    /**
    * The metadata to use when creating the patient.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the patient.
    */
    // @DataMember(Order=2)
    public data: Patient;

    public constructor(init?: Partial<PatientPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientSingleResponse(); }
    public getTypeName() { return 'PatientPostCreate'; }
}

/**
* Form - Patient
*/
// @Route("/melanoma/form/patient/save", "POST")
// @Api(Description="Form - Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSave implements IReturn<PatientSingleResponse>
{
    /**
    * The metadata to use when creating the patient.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The patient to save.
    */
    // @DataMember(Order=2)
    public data: Patient;

    public constructor(init?: Partial<PatientPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientSingleResponse(); }
    public getTypeName() { return 'PatientPostSave'; }
}

/**
* Form - Patient
*/
// @Route("/melanoma/form/patient/validate", "POST")
// @Api(Description="Form - Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientPostValidate implements IReturn<PatientValidationResponse>
{
    /**
    * The metadata to use when validating the patient.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The patient to validate.
    */
    // @DataMember(Order=2)
    public data: Patient;

    public constructor(init?: Partial<PatientPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new PatientValidationResponse(); }
    public getTypeName() { return 'PatientPostValidate'; }
}

/**
* Form - PrimaryMelanoma
*/
// @Route("/melanoma/form/primary-melanoma/single/id/{Id}", "GET")
// @Api(Description="Form - PrimaryMelanoma")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaSingleResponse)", StatusCode=500)
// @DataContract
export class PrimaryMelanomaGetSingleById implements IReturn<PrimaryMelanomaSingleResponse>, IDataGetSingleById {
    /**
    * The ID of the primary melanoma to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PrimaryMelanomaGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new PrimaryMelanomaSingleResponse(); }
    public getTypeName() { return 'PrimaryMelanomaGetSingleById'; }
}

/**
* Form - PrimaryMelanoma
*/
// @Route("/melanoma/form/primary-melanoma/collection", "GET")
// @Api(Description="Form - PrimaryMelanoma")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaCollectionResponse)", StatusCode=500)
// @DataContract
export class PrimaryMelanomaGetCollection implements IReturn<PrimaryMelanomaCollectionResponse>, IDataGetCollection {
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<PrimaryMelanomaGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new PrimaryMelanomaCollectionResponse(); }
    public getTypeName() { return 'PrimaryMelanomaGetCollection'; }
}

/**
* Form - PrimaryMelanoma
*/
// @Route("/melanoma/form/primary-melanoma/create", "POST")
// @Api(Description="Form - PrimaryMelanoma")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaSingleResponse)", StatusCode=500)
// @DataContract
export class PrimaryMelanomaPostCreate implements IReturn<PrimaryMelanomaSingleResponse>
{
    /**
    * The metadata to use when creating the primary melanoma.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the primary melanoma.
    */
    // @DataMember(Order=2)
    public data: PrimaryMelanoma;

    public constructor(init?: Partial<PrimaryMelanomaPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new PrimaryMelanomaSingleResponse(); }
    public getTypeName() { return 'PrimaryMelanomaPostCreate'; }
}

/**
* Form - PrimaryMelanoma
*/
// @Route("/melanoma/form/primary-melanoma/save", "POST")
// @Api(Description="Form - PrimaryMelanoma")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaSingleResponse)", StatusCode=500)
// @DataContract
export class PrimaryMelanomaPostSave implements IReturn<PrimaryMelanomaSingleResponse>
{
    /**
    * The metadata to use when creating the primary melanoma.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The primary melanoma to save.
    */
    // @DataMember(Order=2)
    public data: PrimaryMelanoma;

    public constructor(init?: Partial<PrimaryMelanomaPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new PrimaryMelanomaSingleResponse(); }
    public getTypeName() { return 'PrimaryMelanomaPostSave'; }
}

/**
* Form - PrimaryMelanoma
*/
// @Route("/melanoma/form/primary-melanoma/validate", "POST")
// @Api(Description="Form - PrimaryMelanoma")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaValidationResponse)", StatusCode=500)
// @DataContract
export class PrimaryMelanomaPostValidate implements IReturn<PrimaryMelanomaValidationResponse>
{
    /**
    * The metadata to use when validating the primary melanoma.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The primary melanoma to validate.
    */
    // @DataMember(Order=2)
    public data: PrimaryMelanoma;

    public constructor(init?: Partial<PrimaryMelanomaPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new PrimaryMelanomaValidationResponse(); }
    public getTypeName() { return 'PrimaryMelanomaPostValidate'; }
}

/**
* Form - PrimaryMelanoma
*/
// @Route("/melanoma/form/primary-melanoma/single/patient-id-repeat/{PatientId}/{Repeat}", "GET")
// @Api(Description="Form - PrimaryMelanoma")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaSingleResponse)", StatusCode=500)
// @DataContract
export class PrimaryMelanomaGetSingleByPatientIdAndRepeat implements IReturn<PrimaryMelanomaSingleResponse>, IFormGetSingleByPatientIdAndRepeat {
    /**
    * The id of the patient.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The repeat of the primary melanoma to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<PrimaryMelanomaGetSingleByPatientIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new PrimaryMelanomaSingleResponse(); }
    public getTypeName() { return 'PrimaryMelanomaGetSingleByPatientIdAndRepeat'; }
}

/**
* Form - PrimaryMelanoma
*/
// @Route("/melanoma/form/primary-melanoma/collection/patient-id/{PatientId}", "GET")
// @Api(Description="Form - PrimaryMelanoma")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.PrimaryMelanomaCollectionResponse)", StatusCode=500)
// @DataContract
export class PrimaryMelanomaGetCollectionByPatientId implements IReturn<PrimaryMelanomaCollectionResponse>, IFormGetCollectionByPatientId {
    /**
    * The id of the patient.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * How many items to skip.
    */
    // @DataMember(Order=2)
    public skip?: number;

    /**
    * How many items to return.
    */
    // @DataMember(Order=3)
    public take?: number;

    public constructor(init?: Partial<PrimaryMelanomaGetCollectionByPatientId>) { (Object as any).assign(this, init); }
    public createResponse() { return new PrimaryMelanomaCollectionResponse(); }
    public getTypeName() { return 'PrimaryMelanomaGetCollectionByPatientId'; }
}

/**
* Form - ReportType
*/
// @Route("/melanoma/form/report-type/single/id/{Id}", "GET")
// @Api(Description="Form - ReportType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ReportTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ReportTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ReportTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ReportTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ReportTypeSingleResponse)", StatusCode=500)
// @DataContract
export class ReportTypeGetSingleById implements IReturn<ReportTypeSingleResponse>, IDataGetSingleById {
    /**
    * The ID of the report type to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ReportTypeGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new ReportTypeSingleResponse(); }
    public getTypeName() { return 'ReportTypeGetSingleById'; }
}

/**
* Form - ReportType
*/
// @Route("/melanoma/form/report-type/collection", "GET")
// @Api(Description="Form - ReportType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ReportTypeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ReportTypeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ReportTypeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ReportTypeCollectionResponse)", StatusCode=500)
// @DataContract
export class ReportTypeGetCollection implements IReturn<ReportTypeCollectionResponse>, IDataGetCollection {
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<ReportTypeGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new ReportTypeCollectionResponse(); }
    public getTypeName() { return 'ReportTypeGetCollection'; }
}

/**
* Form - ReportType
*/
// @Route("/melanoma/form/report-type/create", "POST")
// @Api(Description="Form - ReportType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ReportTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ReportTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ReportTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ReportTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ReportTypeSingleResponse)", StatusCode=500)
// @DataContract
export class ReportTypePostCreate implements IReturn<ReportTypeSingleResponse>
{
    /**
    * The metadata to use when creating the report type.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the report type.
    */
    // @DataMember(Order=2)
    public data: ReportType;

    public constructor(init?: Partial<ReportTypePostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ReportTypeSingleResponse(); }
    public getTypeName() { return 'ReportTypePostCreate'; }
}

/**
* Form - ReportType
*/
// @Route("/melanoma/form/report-type/save", "POST")
// @Api(Description="Form - ReportType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ReportTypeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ReportTypeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ReportTypeSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ReportTypeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ReportTypeSingleResponse)", StatusCode=500)
// @DataContract
export class ReportTypePostSave implements IReturn<ReportTypeSingleResponse>
{
    /**
    * The metadata to use when creating the report type.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The report type to save.
    */
    // @DataMember(Order=2)
    public data: ReportType;

    public constructor(init?: Partial<ReportTypePostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new ReportTypeSingleResponse(); }
    public getTypeName() { return 'ReportTypePostSave'; }
}

/**
* Form - ReportType
*/
// @Route("/melanoma/form/report-type/validate", "POST")
// @Api(Description="Form - ReportType")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.ReportTypeValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.ReportTypeValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.ReportTypeValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.ReportTypeValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.ReportTypeValidationResponse)", StatusCode=500)
// @DataContract
export class ReportTypePostValidate implements IReturn<ReportTypeValidationResponse>
{
    /**
    * The metadata to use when validating the report type.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The report type to validate.
    */
    // @DataMember(Order=2)
    public data: ReportType;

    public constructor(init?: Partial<ReportTypePostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new ReportTypeValidationResponse(); }
    public getTypeName() { return 'ReportTypePostValidate'; }
}

/**
* Form - Surgery
*/
// @Route("/melanoma/form/surgery/single/id/{Id}", "GET")
// @Api(Description="Form - Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryGetSingleById implements IReturn<SurgerySingleResponse>, IDataGetSingleById {
    /**
    * The ID of the surgery to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SurgeryGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgerySingleResponse(); }
    public getTypeName() { return 'SurgeryGetSingleById'; }
}

/**
* Form - Surgery
*/
// @Route("/melanoma/form/surgery/collection", "GET")
// @Api(Description="Form - Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryCollectionResponse)", StatusCode=500)
// @DataContract
export class SurgeryGetCollection implements IReturn<SurgeryCollectionResponse>, IDataGetCollection {
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<SurgeryGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryCollectionResponse(); }
    public getTypeName() { return 'SurgeryGetCollection'; }
}

/**
* Form - Surgery
*/
// @Route("/melanoma/form/surgery/create", "POST")
// @Api(Description="Form - Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryPostCreate implements IReturn<SurgerySingleResponse>
{
    /**
    * The metadata to use when creating the surgery.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the surgery.
    */
    // @DataMember(Order=2)
    public data: Surgery;

    public constructor(init?: Partial<SurgeryPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgerySingleResponse(); }
    public getTypeName() { return 'SurgeryPostCreate'; }
}

/**
* Form - Surgery
*/
// @Route("/melanoma/form/surgery/save", "POST")
// @Api(Description="Form - Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryPostSave implements IReturn<SurgerySingleResponse>
{
    /**
    * The metadata to use when creating the surgery.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The surgery to save.
    */
    // @DataMember(Order=2)
    public data: Surgery;

    public constructor(init?: Partial<SurgeryPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgerySingleResponse(); }
    public getTypeName() { return 'SurgeryPostSave'; }
}

/**
* Form - Surgery
*/
// @Route("/melanoma/form/surgery/validate", "POST")
// @Api(Description="Form - Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryValidationResponse)", StatusCode=500)
// @DataContract
export class SurgeryPostValidate implements IReturn<SurgeryValidationResponse>
{
    /**
    * The metadata to use when validating the surgery.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The surgery to validate.
    */
    // @DataMember(Order=2)
    public data: Surgery;

    public constructor(init?: Partial<SurgeryPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryValidationResponse(); }
    public getTypeName() { return 'SurgeryPostValidate'; }
}

/**
* Form - Surgery
*/
// @Route("/melanoma/form/surgery/single/patient-id-repeat/{PatientId}/{Repeat}", "GET")
// @Api(Description="Form - Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgerySingleResponse)", StatusCode=500)
// @DataContract
export class SurgeryGetSingleByPatientIdAndRepeat implements IReturn<SurgerySingleResponse>, IFormGetSingleByPatientIdAndRepeat {
    /**
    * The id of the patient.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The repeat of the surgery to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<SurgeryGetSingleByPatientIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgerySingleResponse(); }
    public getTypeName() { return 'SurgeryGetSingleByPatientIdAndRepeat'; }
}

/**
* Form - Surgery
*/
// @Route("/melanoma/form/surgery/collection/patient-id/{PatientId}", "GET")
// @Api(Description="Form - Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.SurgeryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.SurgeryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.SurgeryCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.SurgeryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.SurgeryCollectionResponse)", StatusCode=500)
// @DataContract
export class SurgeryGetCollectionByPatientId implements IReturn<SurgeryCollectionResponse>, IFormGetCollectionByPatientId {
    /**
    * The id of the patient.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * How many items to skip.
    */
    // @DataMember(Order=2)
    public skip?: number;

    /**
    * How many items to return.
    */
    // @DataMember(Order=3)
    public take?: number;

    public constructor(init?: Partial<SurgeryGetCollectionByPatientId>) { (Object as any).assign(this, init); }
    public createResponse() { return new SurgeryCollectionResponse(); }
    public getTypeName() { return 'SurgeryGetCollectionByPatientId'; }
}

/**
* Form - Treatment
*/
// @Route("/melanoma/form/treatment/single/id/{Id}", "GET")
// @Api(Description="Form - Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetSingleById implements IReturn<TreatmentSingleResponse>, IDataGetSingleById {
    /**
    * The ID of the treatment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new TreatmentSingleResponse(); }
    public getTypeName() { return 'TreatmentGetSingleById'; }
}

/**
* Form - Treatment
*/
// @Route("/melanoma/form/treatment/collection", "GET")
// @Api(Description="Form - Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetCollection implements IReturn<TreatmentCollectionResponse>, IDataGetCollection {
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<TreatmentGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new TreatmentCollectionResponse(); }
    public getTypeName() { return 'TreatmentGetCollection'; }
}

/**
* Form - Treatment
*/
// @Route("/melanoma/form/treatment/create", "POST")
// @Api(Description="Form - Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentPostCreate implements IReturn<TreatmentSingleResponse>
{
    /**
    * The metadata to use when creating the treatment.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the treatment.
    */
    // @DataMember(Order=2)
    public data: Treatment;

    public constructor(init?: Partial<TreatmentPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new TreatmentSingleResponse(); }
    public getTypeName() { return 'TreatmentPostCreate'; }
}

/**
* Form - Treatment
*/
// @Route("/melanoma/form/treatment/save", "POST")
// @Api(Description="Form - Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentPostSave implements IReturn<TreatmentSingleResponse>
{
    /**
    * The metadata to use when creating the treatment.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The treatment to save.
    */
    // @DataMember(Order=2)
    public data: Treatment;

    public constructor(init?: Partial<TreatmentPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new TreatmentSingleResponse(); }
    public getTypeName() { return 'TreatmentPostSave'; }
}

/**
* Form - Treatment
*/
// @Route("/melanoma/form/treatment/validate", "POST")
// @Api(Description="Form - Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentValidationResponse)", StatusCode=500)
// @DataContract
export class TreatmentPostValidate implements IReturn<TreatmentValidationResponse>
{
    /**
    * The metadata to use when validating the treatment.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The treatment to validate.
    */
    // @DataMember(Order=2)
    public data: Treatment;

    public constructor(init?: Partial<TreatmentPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new TreatmentValidationResponse(); }
    public getTypeName() { return 'TreatmentPostValidate'; }
}

/**
* Form - Treatment
*/
// @Route("/melanoma/form/treatment/single/patient-id-repeat/{PatientId}/{Repeat}", "GET")
// @Api(Description="Form - Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetSingleByPatientIdAndRepeat implements IReturn<TreatmentSingleResponse>, IFormGetSingleByPatientIdAndRepeat {
    /**
    * The id of the patient.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The repeat of the treatment to retrieve.
    */
    // @DataMember(Order=2)
    public repeat?: number;

    public constructor(init?: Partial<TreatmentGetSingleByPatientIdAndRepeat>) { (Object as any).assign(this, init); }
    public createResponse() { return new TreatmentSingleResponse(); }
    public getTypeName() { return 'TreatmentGetSingleByPatientIdAndRepeat'; }
}

/**
* Form - Treatment
*/
// @Route("/melanoma/form/treatment/collection/patient-id/{PatientId}", "GET")
// @Api(Description="Form - Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.TreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.TreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.TreatmentCollectionResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.TreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.TreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetCollectionByPatientId implements IReturn<TreatmentCollectionResponse>, IFormGetCollectionByPatientId {
    /**
    * The id of the patient.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * How many items to skip.
    */
    // @DataMember(Order=2)
    public skip?: number;

    /**
    * How many items to return.
    */
    // @DataMember(Order=3)
    public take?: number;

    public constructor(init?: Partial<TreatmentGetCollectionByPatientId>) { (Object as any).assign(this, init); }
    public createResponse() { return new TreatmentCollectionResponse(); }
    public getTypeName() { return 'TreatmentGetCollectionByPatientId'; }
}

/**
* Form - UserRoles
*/
// @Route("/melanoma/form/user-roles/single/id/{Id}", "GET")
// @Api(Description="Form - UserRoles")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UserRolesSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UserRolesSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.UserRolesSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UserRolesSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UserRolesSingleResponse)", StatusCode=500)
// @DataContract
export class UserRolesGetSingleById implements IReturn<UserRolesSingleResponse>, IDataGetSingleById {
    /**
    * The ID of the user roles to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<UserRolesGetSingleById>) { (Object as any).assign(this, init); }
    public createResponse() { return new UserRolesSingleResponse(); }
    public getTypeName() { return 'UserRolesGetSingleById'; }
}

/**
* Form - UserRoles
*/
// @Route("/melanoma/form/user-roles/collection", "GET")
// @Api(Description="Form - UserRoles")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UserRolesCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UserRolesCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UserRolesCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UserRolesCollectionResponse)", StatusCode=500)
// @DataContract
export class UserRolesGetCollection implements IReturn<UserRolesCollectionResponse>, IDataGetCollection {
    /**
    * The number of entries to skip.
    */
    // @DataMember(Order=1)
    public skip?: number;

    /**
    * The number of entries to return.
    */
    // @DataMember(Order=2)
    public take?: number;

    public constructor(init?: Partial<UserRolesGetCollection>) { (Object as any).assign(this, init); }
    public createResponse() { return new UserRolesCollectionResponse(); }
    public getTypeName() { return 'UserRolesGetCollection'; }
}

/**
* Form - UserRoles
*/
// @Route("/melanoma/form/user-roles/create", "POST")
// @Api(Description="Form - UserRoles")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UserRolesSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UserRolesSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.UserRolesSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UserRolesSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UserRolesSingleResponse)", StatusCode=500)
// @DataContract
export class UserRolesPostCreate implements IReturn<UserRolesSingleResponse>
{
    /**
    * The metadata to use when creating the user roles.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The values to prefill on the user roles.
    */
    // @DataMember(Order=2)
    public data: UserRoles;

    public constructor(init?: Partial<UserRolesPostCreate>) { (Object as any).assign(this, init); }
    public createResponse() { return new UserRolesSingleResponse(); }
    public getTypeName() { return 'UserRolesPostCreate'; }
}

/**
* Form - UserRoles
*/
// @Route("/melanoma/form/user-roles/save", "POST")
// @Api(Description="Form - UserRoles")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UserRolesSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UserRolesSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.UserRolesSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UserRolesSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UserRolesSingleResponse)", StatusCode=500)
// @DataContract
export class UserRolesPostSave implements IReturn<UserRolesSingleResponse>
{
    /**
    * The metadata to use when creating the user roles.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The user roles to save.
    */
    // @DataMember(Order=2)
    public data: UserRoles;

    public constructor(init?: Partial<UserRolesPostSave>) { (Object as any).assign(this, init); }
    public createResponse() { return new UserRolesSingleResponse(); }
    public getTypeName() { return 'UserRolesPostSave'; }
}

/**
* Form - UserRoles
*/
// @Route("/melanoma/form/user-roles/validate", "POST")
// @Api(Description="Form - UserRoles")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Forms.Form.UserRolesValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Forms.Form.UserRolesValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Forms.Form.UserRolesValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Forms.Form.UserRolesValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Forms.Form.UserRolesValidationResponse)", StatusCode=500)
// @DataContract
export class UserRolesPostValidate implements IReturn<UserRolesValidationResponse>
{
    /**
    * The metadata to use when validating the user roles.
    */
    // @DataMember(Order=1)
    public metadata: { [index: string]: Object; };

    /**
    * The user roles to validate.
    */
    // @DataMember(Order=2)
    public data: UserRoles;

    public constructor(init?: Partial<UserRolesPostValidate>) { (Object as any).assign(this, init); }
    public createResponse() { return new UserRolesValidationResponse(); }
    public getTypeName() { return 'UserRolesPostValidate'; }
}

// @Route("/melanoma/file/view/{Id}", "GET")
export class GetFile implements IReturn<Blob>, IGet {
    public id?: number;

    public constructor(init?: Partial<GetFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'GetFile'; }
}

// @Route("/melanoma/file/download/{Id}", "GET")
export class DownloadFile implements IReturn<Blob>, IGet {
    public id?: number;

    public constructor(init?: Partial<DownloadFile>) { (Object as any).assign(this, init); }
    public createResponse() { return new Blob(); }
    public getTypeName() { return 'DownloadFile'; }
}
