import { FormsContext } from '@ngt/forms';
import { JsonServiceClient } from '@servicestack/client';
import { useCallback, useContext, useMemo } from 'react';
import useSWR, { mutate, SWRConfiguration } from 'swr';
import { UserWithRoleValueSearch, UserWithRoleValueSearchResponse } from '../apis/dtos';
import UserRoles from '../containers/UserRoles/UserRoles';
import { BoundMutator } from '../utilities/BoundMutator';


// @ts-ignore: declared but not used 'url'
const fetcher = async (
    url: string,
    client: JsonServiceClient,
    username?: string,
    role?: number,
    page?: number,
    rows?: number,
) => {
    return await client?.get(new UserWithRoleValueSearch({
        username,
        role,
        page,
        pageSize: rows
    }));
}

const urlKey = '/api/users/withrolevalues';

const useUserRoles = (
    userName?: string,
    role?: number,
    page?: number,
    rows?: number,
    configuration?: SWRConfiguration<UserWithRoleValueSearchResponse, UserWithRoleValueSearchResponse>
) => {
    const { serviceStackClient: client } = useContext(FormsContext);

    const cacheKey = useMemo(() => {
        return [urlKey, client, userName, role, page, rows]
    }, [client, userName, role, page, rows])

    const { data, error } = useSWR<UserWithRoleValueSearchResponse, UserWithRoleValueSearchResponse>(cacheKey, fetcher, configuration);

    const boundMutate: BoundMutator<UserWithRoleValueSearchResponse> = useCallback((data, shouldRevalidate) => {
        return mutate(cacheKey, data, shouldRevalidate);
    }, [cacheKey]);

    return {
        data: data?.data ? data?.data : UserRoles[0],
        total: data?.count,
        error: error?.responseStatus,
        loading: data === undefined && error === undefined,
        mutate: boundMutate
    };
}

export default useUserRoles;