import { JsonServiceClient } from '@servicestack/client';
import { History } from 'history';


declare let global: any; // populated from package.json/jest

if (typeof global === 'undefined') {
    (window as any).global = window;
}

export let client = new JsonServiceClient('/');

const isNode = typeof process === 'object' &&
    typeof process.versions === 'object' &&
    typeof process.versions.node !== 'undefined';

if (isNode) {
    const packageConfig = require("../../package.json");
    let baseUrl = packageConfig["proxy"];
    client = new JsonServiceClient(baseUrl);
    if (baseUrl.startsWith("https://localhost") || baseUrl.startsWith("https://127.0.0.1")) {
        // allow self-signed certs
        client.requestFilter = (req) => {
            const https = require('https');
            (req as any).agent = new https.Agent({ rejectUnauthorized: false });
        };
    }
}

export {
    classNames, errorResponse, errorResponseExcept, queryString, splitOnFirst, toPascalCase
} from '@servicestack/client';

export enum Routes {
    Home = '/',
    PatientForms = '/patient/:patientId/:formDefinitionCode',
    PatientForm = '/patient/:patientId/:formDefinitionCode/:formRepeat',
    PatientFormNew = '/patient/:patientId/:formDefinitionCode/create',
    Patients = '/patients',
    PatientNew = '/patient/create',
    PatientById = '/patient/:formId',
    Reports = '/reports',
    ReportById = '/reports/:formId',
    Lookups = '/lookup',
    LookupById = '/lookup/:formId',
    Admin = '/admin',
    SignIn = '/',
    Forbidden = '/forbidden',
    Users = '/users',
    UsersNew = '/user/create',
    UserById = '/user/:formId',
}

export enum Roles {
    Admin = 'Admin',
}

export const redirect = (history: History, path: string) => {
    setTimeout(() => {
        const externalUrl = path.indexOf('://') >= 0;
        if (!externalUrl) {
            history.push(path);
        } else {
            document.location.href = path;
        }
    }, 0);
}