/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the component that provides the basis for all routes.
 * --------------------------------------------------------------------------------
 */

import { FormDefinitionSingleResponse, FormsContext, FormTypeSingleResponse, IDataSingleResponse, IForm, IFormMapping, LookupCollectionResponse } from '@ngt/forms';
import * as React from 'react';
import { useContext } from 'react';
import { RouteProps } from 'react-router';
import { match, Route, useParams } from 'react-router-dom';
import { Configuration } from 'swr/dist/types';
import { IPatientForm } from '../../apis/dtos';
import { IDataCollectionResponse } from '../../hooks/usePatientFormsByPatientId';
import PatientFormsByPatientIdResolver from '../Resolvers/PatientFormsByPatientIdResolver';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

/**
 * This interface defines the properties for the OpmsFormByIdRoute component.
 */
export interface IPatientFormsByPatientIdRouteProps extends RouteProps {
    formDefinitionCode?: string;
    patientId?: number;

    computedMatch?: match<Record<string, string>>;

    /**
     * This property determines whether the associated data should be loaded before loading the route.
     */
    resolveBeforeLoad?: boolean | null;

    formMapping?: Record<string, IFormMapping>;

    patientConfiguration?: Partial<Configuration<IDataSingleResponse<IForm>, IDataSingleResponse<IForm>>>;
    formDefinitionConfiguration?: Partial<Configuration<FormDefinitionSingleResponse, FormDefinitionSingleResponse>>;
    lookupConfiguration?: Partial<Configuration<LookupCollectionResponse, LookupCollectionResponse>>;
    formsConfiguration?: Partial<Configuration<IDataCollectionResponse<IPatientForm>, IDataCollectionResponse<IPatientForm>>>;
    formTypeConfiguration?: Partial<Configuration<FormTypeSingleResponse, FormTypeSingleResponse>>;
}

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

/**
 * This component provides context for the form management system.
 * @param param0 component properties.
 */
const PatientFormsByPatientIdRoute: React.FunctionComponent<IPatientFormsByPatientIdRouteProps> = ({
    formDefinitionCode,
    patientId,
    resolveBeforeLoad,
    children,
    formMapping,

    patientConfiguration,
    formsConfiguration,
    lookupConfiguration,
    formDefinitionConfiguration,
    formTypeConfiguration,

    ...routeProps
}) => {
    const routeParams = useParams<Record<string, string>>();
    const formsContext = useContext(FormsContext);
    const params = routeProps.computedMatch?.params ?? routeParams;
    const paramPatientIdStr = params['patientId'];
    const paramFormDefCode = params[formsContext.routeParameters.formDefinitionCode];
    let paramFormRepeat = null;
    let paramPatientId = null;

    try {
        if (paramPatientIdStr) {
            paramPatientId = parseInt(paramPatientIdStr);
        }
    }
    catch (error) {
        console.error(`Invalid Patient ID in route: ${paramPatientIdStr}`)
    }

    return <Route {...routeProps}>
        <PatientFormsByPatientIdResolver
            formDefinitionCode={paramFormDefCode ?? formDefinitionCode ?? null}
            patientId={paramPatientId ?? patientId ?? null}
            resolveBeforeLoad={resolveBeforeLoad}
            formMapping={formMapping}
            formsConfiguration={formsConfiguration}
            formDefinitionConfiguration={formDefinitionConfiguration}
            formTypeConfiguration={formTypeConfiguration}
            lookupConfiguration={lookupConfiguration}
            patientConfiguration={patientConfiguration}
        >
            {children}
        </PatientFormsByPatientIdResolver>
    </Route>
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default PatientFormsByPatientIdRoute;