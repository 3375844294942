import { FormByIdRoute, IFormMapping, useAuthenticatedUser } from '@ngt/forms';
import { Forbidden } from '@servicestack/react';
import 'es6-shim';
import React, { FunctionComponent } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { IPatientForm, UserDto } from './apis/dtos';
import AuthedLayout from './components/Layouts/AuthedLayout';
import PatientFormByPatientIdAndRepeatRoute from './components/Routes/PatientFormByPatientIdAndRepeatRoute';
import PatientFormsByPatientIdRoute from './components/Routes/PatientFormsByPatientIdRoute';
import LookupType from './containers/Forms/LookupType';
import Patient from './containers/Forms/Patient';
import PrimaryMelanoma from './containers/Forms/PrimaryMelanoma';
import Surgery from './containers/Forms/Surgery';
import Metastases from './containers/Forms/Metastases';
import Treatment from './containers/Forms/Treatment';
import { Patients } from './containers/index';
import LookupTypes from './containers/Lookups/LookupTypes';
import ReportTypes from './containers/Reports/ReportTypes';
import UserRoles from './containers/UserRoles/UserRoles';
import PatientForms from './containers/PatientForms/PatientForms';
import { Routes } from './shared';
import { IPatientFormMapping } from './utilities/IPatientFormMapping';
import UserRole from './containers/Forms/UserRole';
import ForbiddenAccess from './containers/Shared/ForbiddenAccess';


// Get global predefined state.

const formMapping: Record<string, IFormMapping> = {
    "patient": {
        components: <Patient />
    },
    "userRoles": {
        components: <UserRole />
    },
    "lookup": {
        components: <LookupType />
    }
}

const createData = (patientId: number | null) => {
    return {
        parentFormDefinitionId: 3,
        parentFormId: patientId
    } as IPatientForm
}


const patientFormMapping: Record<string, IPatientFormMapping> = {
    "primary-melanoma": {
        components: <PrimaryMelanoma />,
        createData
    },
    "surgery": {
        components: <Surgery />,
        createData
    },
    "metastases": {
        components: <Metastases />,
        createData
    },
    "treatment": {
        components: <Treatment />,
        createData
    }
}

const patientFormsMapping: Record<string, IFormMapping> = {
    "primary-melanoma": {
        components: <PatientForms title="Primary Melanomas" newButtonTitle="Primary Melanoma" />
    },
    "surgery": {
        components: <PatientForms title="Surgeries" newButtonTitle="Surgery" />
    },
    "metastases": {
        components: <PatientForms title="Metastases" newButtonTitle="Metastasis" />
    },
    "treatment": {
        components: <PatientForms title="Treatments" newButtonTitle="Treatment" />
    }
}

const userFormsMapping: Record<string, IFormMapping> = {
}

export const App: FunctionComponent = () => {
    const { data: authenticated } = useAuthenticatedUser<UserDto>();

    return (<Router>
        {
            !!authenticated && <AuthedLayout>
                <Switch>
                    <PatientFormByPatientIdAndRepeatRoute
                        path={[
                            Routes.PatientFormNew,
                            Routes.PatientForm
                        ]}
                        formMapping={patientFormMapping}
                        resolveBeforeLoad
                    />
                    <FormByIdRoute
                        path={[
                            Routes.UsersNew,
                            Routes.UserById
                        ]}
                        formDefinitionCode="userRoles"
                        formMapping={formMapping}
                        resolveBeforeLoad
                    />
                    <PatientFormsByPatientIdRoute
                        path={[
                            Routes.PatientForms
                        ]}
                        formMapping={patientFormsMapping}
                        resolveBeforeLoad
                    />
                    <FormByIdRoute
                        path={[
                            Routes.PatientNew,
                            Routes.PatientById
                        ]}
                        formDefinitionCode="patient"
                        formMapping={formMapping}
                        resolveBeforeLoad
                    />
                    <FormByIdRoute
                        path={[
                            Routes.LookupById
                        ]}
                        formDefinitionCode="lookup"
                        formMapping={formMapping}
                        resolveBeforeLoad
                    />
                    <Route exact path={Routes.Patients} component={Patients} />
                    {authenticated?.isAdmin && <Route exact path={Routes.Admin} />}
                    <Route exact path={Routes.Users} component={UserRoles} />
                    <Route exact path={Routes.Reports} component={ReportTypes} />
                    <Route exact path={Routes.Lookups} component={LookupTypes} />
                    <Redirect exact path={Routes.Home} to={Routes.Patients} />
                    <Route exact path={Routes.Forbidden} component={ForbiddenAccess} />
                </Switch>
            </AuthedLayout>
        }
        {
            !authenticated && <Route exact path={Routes.Forbidden} component={ForbiddenAccess} />
        }
    </Router>);
}
