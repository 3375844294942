import { Container, makeStyles, Paper } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import Footer from './Footer';
import Header, { IMenuItem } from './Header';

interface IDefaultLayoutProps {
    menuItems?: IMenuItem[]
}

const useStyles = makeStyles(theme => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    content: {
        margin: theme.spacing(0, 0, 3)
    }
}));

const DefaultLayout: FunctionComponent<IDefaultLayoutProps> = ({ children, menuItems }) => {
    const classes = useStyles();

    return <div>
        <Header menuItems={menuItems}>
        </Header>
        <Container maxWidth="lg">
            <Paper square className={classes.content}>
                {children}
                <Footer></Footer>
            </Paper>
        </Container>
    </div>
}

export default DefaultLayout