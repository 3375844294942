import { Button, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import { Checkbox, ContextForm, DatePicker, FormContext, GroupedField, IValidationError, Numeric, Select, SubmitButton, Text, TextArea, useAuthenticatedUser } from '@ngt/forms';
import { IFormSubmit } from '@ngt/forms-core';
import React, { FunctionComponent, useCallback, useContext, useState, useEffect, useRef } from 'react';
import { generatePath, Redirect, useHistory, useParams } from 'react-router-dom';
import { UserDto, UserRoles } from '../../apis/dtos';
import AgeDisplay from '../../components/Form/AgeDisplay';
import BlockNavigation from '../../components/Form/BlockNavigation';
import { Routes } from '../../shared';
import lookupFilterProps from '../../utilities/lookupFilterProps';

interface IUserProps {

}

const useStyles = makeStyles(theme => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    selectors: {
        padding: theme.spacing(0, 2)
    },
    alert: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    container: {
        padding: theme.spacing(1, 2)
    },
    wrapper: {
        display: 'inline-flex',
        position: 'relative',
        padding: theme.spacing(1)
    }
}));

const UserForm: FunctionComponent<IUserProps> = () => {
    const classes = useStyles();
    const formContext = useContext(FormContext);
    const user = formContext.data as UserRoles | undefined;
    const history = useHistory();
    const routeParams = useParams<Record<string, string>>();

    const redirectNewUser: IFormSubmit<UserRoles, IValidationError> = useCallback(async (formState, formActions) => {
        //if (!routeParams?.formId) {
        //    history.push(generatePath(Routes.UserById, { formId: formState.values.id! }));
        //}
        history.push(generatePath(Routes.Users));
    }, [routeParams, history])

    const goBackToList = () => {
        history.push(generatePath(Routes.Users));
    }

    const deleteRecord = () => {
        var deleteConfirmation = window.confirm("Do you want to delete this user record?");
        if (deleteConfirmation == true) {
            // add the deletion code here
            let deleteResult: string = '';

            const baseUrl = window.location.origin;

            fetch(baseUrl + "/api/record-delete-id/user/UserRoles/" + routeParams.formId + "?format=json")
                .then(res => res.json())
                .then(
                    (result) => {
                        if (typeof result.deletionResult != 'undefined') {
                            deleteResult = result.deletionResult[0];
                            window.alert(deleteResult);
                        }
                    },
                    (error) => {
                        window.alert(error);
                    }
                )

            history.push(generatePath(Routes.Users));

        } else {
            //console.log('delete? - You pressed Cancel');
        }
    }

    const xs = 12;
    const sm = 12;
    const md = 6;
    const lg = 4;
    const xl = 4;

    // Authenticate user
    const { data: authedUser } = useAuthenticatedUser<UserDto>();
    if (!authedUser?.isAdmin) {
        // Redirect to forbidden page
        return <Redirect to={Routes.Forbidden} />
    }

    return (
        <>
            {
                //console.log('user role id page')
            }
            <ContextForm onAfterSubmit={redirectNewUser} >
                <BlockNavigation />
                <Grid container>
                    <Grid container item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={classes.container}>
                        <Typography variant="h2" component="h2" gutterBottom>
                            User Details
                        </Typography>
                    </Grid>
                </Grid>
                { /*<GroupedField name="id" component={Numeric} disabled />*/}
                <GroupedField name="username" component={Text} />
                <GroupedField name="role" component={Select} LookupFilterProps={lookupFilterProps} />



                <Grid container justify='space-between' >
                    <Grid item >
                        <SubmitButton color="primary" variant="contained">
                            Save
                        </SubmitButton>
                    </Grid>
                    <Grid item >
                        <div className={classes.wrapper}>
                            <Button color="primary" variant="contained" onClick={deleteRecord} >Delete</Button>
                        </div>
                    </Grid>

                    <Grid item container >
                        <div className={classes.wrapper}>
                            <Button color="primary" variant="contained" onClick={goBackToList} >Cancel</Button>
                        </div>
                    </Grid>
                </Grid>
            </ContextForm>
        </>
    )
}

export default UserForm