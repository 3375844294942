import { FormsContext } from '@ngt/forms';
import { JsonServiceClient } from '@servicestack/client';
import { useCallback, useContext, useMemo } from 'react';
import useSWR, { mutate, SWRConfiguration } from 'swr';
import { ReportTypeGetCollectionBySearch, ReportTypeSearchResponse } from '../apis/dtos';
import { BoundMutator } from '../utilities/BoundMutator';


// @ts-ignore: declared but not used 'url'
const fetcher = async (
    url: string,
    client: JsonServiceClient,
    name?: string,
    page?: number,
    rows?: number,
) => {
    return await client?.get(new ReportTypeGetCollectionBySearch({
        name,
        page,
        pageSize: rows
    }));
}

const urlKey = '/api/report-type';

const useReportTypes = (
    name?: string,
    page?: number,
    rows?: number,
    configuration?: SWRConfiguration<ReportTypeSearchResponse, ReportTypeSearchResponse>
) => {
    const { serviceStackClient: client } = useContext(FormsContext);

    const cacheKey = useMemo(() => {
        return [urlKey, client, name, page, rows]
    }, [client, name, page, rows])

    const { data, error } = useSWR<ReportTypeSearchResponse, ReportTypeSearchResponse>(cacheKey, fetcher, configuration);

    const boundMutate: BoundMutator<ReportTypeSearchResponse> = useCallback((data, shouldRevalidate) => {
        return mutate(cacheKey, data, shouldRevalidate);
    }, [cacheKey]);

    return {
        data: data?.data,
        total: data?.count,
        error: error?.responseStatus,
        loading: data === undefined && error === undefined,
        mutate: boundMutate
    };
}

export default useReportTypes;