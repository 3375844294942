import { faFileAlt, faSlidersHSquare, faUser, faUsers } from '@fortawesome/pro-solid-svg-icons';
import { makeStyles } from '@material-ui/core';
import { useAuthenticatedUser } from '@ngt/forms';
import React, { FunctionComponent, useEffect } from 'react';
import { UserDto } from '../../apis/dtos';
import { Routes } from './../../shared/index';
import DefaultLayout from './DefaultLayout';
import { IMenuItem } from './Header';


interface IAuthLayoutProps {

}

const useStyles = makeStyles(theme => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    subtitle: {
        padding: theme.spacing(2, 2, 0)
    },
    patientInformation: {
        padding: theme.spacing(2, 2, 0)
    },
    alert: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    }
}));

const AuthedLayout: FunctionComponent<IAuthLayoutProps> = ({ children }) => {
    const classes = useStyles();

    const { data: user } = useAuthenticatedUser<UserDto>();

    const menuItems: IMenuItem[] = React.useMemo(() => {

        const items: IMenuItem[] = [];

        //console.log('user: ', user);

        if (user?.isAdmin || user?.isStandard) { // for users who have 'admin' or 'standard' roles
            items.push({
                url: Routes.Patients,
                label: 'Patients',
                external: false,
                newTab: false,
                icon: faUser
            });
            items.push({
                url: Routes.Reports,
                label: 'Reports',
                external: false,
                newTab: false,
                icon: faFileAlt
            });
            items.push({
                url: Routes.Lookups,
                label: 'Lookups',
                external: false,
                newTab: false,
                icon: faSlidersHSquare
            });
        }

        if (user?.isAdmin) {
            items.push({
                url: Routes.Users,
                label: 'Users',
                external: false,
                newTab: false,
                icon: faUsers
            });
        }

        return items;
    }, [user]);

    return <DefaultLayout menuItems={menuItems}>
        {children}
    </DefaultLayout>
}

export default AuthedLayout