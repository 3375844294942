import { IProgressButtonProps, ProgressButton } from '@ngt/forms';
import { IFormContext, useFormActions, useFormState } from '@ngt/forms-core';
import * as React from 'react';



/**
 * This interface defines the properties for the ResetButton component.
 */
export interface IResetButtonProps<TValues extends object = any> extends Omit<IProgressButtonProps, 'onClick' | 'type'>
{
    onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<TValues, any>) => boolean;
}


/**
 * This component provides context for the patient management system.
 * @param param0 component properties.
 */
const ResetButton = <TValues extends object = any>({
    onClick,
    loading,
    ...buttonProps
}: IResetButtonProps<TValues>) =>
{
    const formActions = useFormActions<TValues, any>();

    const onButtonClick = React.useCallback((event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
    {
        event?.preventDefault();

        let reset = true;

        if (onClick)
        {
            reset = onClick(event, formActions);
        }

        if (reset)
        {
            formActions.reset(true, true);
        }

        return false;
    }, [onClick, formActions]);

    const { submitting } = useFormState({ submitting: true });

    return (
        <ProgressButton type="reset" onClick={onButtonClick} loading={loading || submitting}  {...buttonProps} />
    );
}

export default ResetButton;