import { useFormState } from "@ngt/forms-core";
import { FunctionComponent, useEffect, useMemo } from "react";
import { Prompt } from "react-router-dom";


interface IBlockNavigationProps { }

const subscription = { dirty: true };

const BlockNavigation: FunctionComponent<IBlockNavigationProps> = ({
}) => {
    const message = 'You have unsaved changes. Are you sure you want to discard them?';

    const { dirty } = useFormState(subscription);

    const hasDirtyFields = useMemo(() => {
        if (!dirty) {
            return false;
        }

        return Object.keys(dirty).some(x => dirty[x]);
    }, [dirty]);

    useEffect(() => {
        if (hasDirtyFields) {
            window.onbeforeunload = function () {
                return message;
            }

            return () => {
                window.onbeforeunload = () => { return undefined; };
            };
        }

        return () => { };
    }, [hasDirtyFields])

    return (
        <>
            <Prompt when={hasDirtyFields} message={message} />
        </>
    );
}

export default BlockNavigation;