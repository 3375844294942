/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently loaded form.
 * --------------------------------------------------------------------------------
 */

import React from 'react';
import usePatientPatientFormsByPatientId from '../hooks/usePatientFormsByPatientId';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IPatientFormsContext extends ReturnType<typeof usePatientPatientFormsByPatientId> {
}

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const PatientFormsContext = React.createContext<IPatientFormsContext>({
    data: undefined,
    error: undefined,
    loading: true,
    mutate: async (data, shouldRevalidate) => {
        return undefined;
    }
});

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default PatientFormsContext;