import { faPlus, faSearch, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { GridColDef, GridPageChangeParams, GridRowParams } from '@material-ui/data-grid';
import { asyncDebounce, CollapsibleTable, Form, IFormLabel, InputOnlyField, SubmitButton, Text, useAuthenticatedUser } from '@ngt/forms';
import { IFormContext, IFormSubmit } from '@ngt/forms-core';
import { forEach } from 'lodash-es';
import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { generatePath, Redirect, useHistory } from "react-router-dom";
import { UserDto } from '../../apis/dtos';
import ResetButton from '../../components/Form/ResetButton';
import useUserRoles from '../../hooks/useUserRoles';
import { Routes } from '../../shared';

interface IUserRolesProps {

}

const useStyles = makeStyles(theme => ({
    title: {
        padding: theme.spacing(2, 2, 2)
    },
    selectors: {
        padding: theme.spacing(0, 2)
    },
    alert: {
        padding: theme.spacing(2)
    },
    formPadding: {
        padding: theme.spacing(2)
    },
    buttonGroup: {
        padding: theme.spacing(2),
        textAlign: 'right'
    },
    container: {
        padding: theme.spacing(1, 2)
    }
}));

interface ISearch {
    username?: string;
    role?: number;    
}

const initialData: ISearch = {
    username: undefined,
    role: undefined,
};

const labels: IFormLabel[] = [
    {
        name: 'username',
        label: 'Username',
        detailedLabel: 'Username'
    },
    {
        name: 'role',
        label: 'Role',
        detailedLabel: 'Role'
    }
];

const UserRoles: FunctionComponent<IUserRolesProps> = () => {
    // build error in Azure. Try this fix tomorrow: https://www.benmvp.com/blog/conditional-react-hooks/
    // build error: React Hook "useStyles" is called conditionally. React Hooks must be called in the exact same order in every component render. Did you accidentally call a React Hook after an early return?   react-hooks/rules-of-hooks

    const classes = useStyles();
    const history = useHistory();

    const [page, setPage] = React.useState<number>(0);
    const [pageSize, setPageSize] = React.useState<number>(20);
    const [username, setUsername] = React.useState<string | undefined>('');
    const [role, setRole] = React.useState<number>();
    const [gotRoleValues, setGotRoleValues] = React.useState<boolean>(false);
    //const [tempLoading, setTempLoading] = React.useState<boolean>(true);
    const [roleValues, setRoleValues] = React.useState<any>(null);
    let { data: userRoles, total: userRoleCount, loading: userRolesLoading } =
        useUserRoles(username, role, page, pageSize);
    let tempLoading = true;

    //console.log('tempLoading: ', tempLoading);

    const userRoleColumns: GridColDef[] = useMemo(() => {
        return [
            {
                field: 'username',
                headerName: 'Username',
                width: 180
            },
            {
                field: 'role',
                headerName: 'Role',
                width: 180
            },
        ]
    }, []);


    const onPageChange = (value: GridPageChangeParams) => {
        setPage(value.page)
    };

    const onPageSizeChange = (value: GridPageChangeParams) => {
        setPageSize(value.pageSize)
    };

    const onSubmit = useCallback(async (formState, formActions) => {
        setUsername(formState.values.username);
        setRole(formState.values.role);
    }, [setUsername, setRole])

    const onClearClick = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>, formActions?: IFormContext<ISearch, any>) => {
        setUsername(undefined);
        setRole(undefined);

        return true;
    }

    const onNewClick = () => {
        history.push(Routes.UsersNew);
    }

    const onUserRoleClick = useCallback((params: GridRowParams) => {
        history.push(generatePath(Routes.UserById, { formId: params.id }));
    }, [history]);

    const xs = 12;
    const sm = 12;
    const md = 6;
    const lg = 4;
    const xl = 4;

    // Authenticate user
    const { data: authedUser } = useAuthenticatedUser<UserDto>();
    if (!authedUser?.isAdmin) {
        // Redirect to forbidden page
        return <Redirect to={Routes.Forbidden} />
    }

    return <>
        <style jsx>{`
            div.MuiDataGrid-cell {
                cursor: pointer;
            }
        `}</style>
        <Grid container justifyContent="space-between">
            <Grid container item xs={12} sm={6} md={md} lg={lg} xl={xl} justifyContent="flex-start" className={classes.container}>
                <Typography variant="h2" component="h2" gutterBottom>
                    Users
                </Typography>
            </Grid>
            <Grid container item xs={12} sm={6} md={md} lg={lg} xl={xl} justifyContent="flex-end" className={classes.container}>
                <Button color="primary" variant="contained" onClick={onNewClick}>
                    <FontAwesomeIcon icon={faPlus} fixedWidth />&nbsp;New User
                </Button>
            </Grid>
        </Grid>
        <Form
            initialValues={initialData}
            onSubmit={onSubmit}
            labels={labels}
        >
            <Grid container>
                <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={classes.container}>
                    <InputOnlyField name="username" component={Text} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={classes.container}>
                    <InputOnlyField name="role" component={Text} />
                </Grid>
                <Grid container item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={classes.container}>
                    <Grid container item xs={6} justifyContent="center">
                        <SubmitButton color="primary" variant="outlined">
                            <FontAwesomeIcon icon={faSearch} fixedWidth />&nbsp;Search
                        </SubmitButton>
                    </Grid>
                    <Grid container item xs={6} justifyContent="flex-end">
                        <ResetButton color="primary" variant="outlined" onClick={onClearClick}>
                            <FontAwesomeIcon icon={faTimes} fixedWidth />&nbsp;Clear
                        </ResetButton>
                    </Grid>
                </Grid>
            </Grid>
        </Form>



        <CollapsibleTable
            title="Users"
            entityName="User"
            loading={userRolesLoading}
            rows={userRoles ?? []}
            rowsPerPageOptions={[20, 50, 100]}
            page={page}
            rowCount={userRoleCount ?? 0}
            pageSize={pageSize}
            paginationMode="server"
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            onRowClick={onUserRoleClick}
            autoHeight
            columns={userRoleColumns}
        />
    </>
}

export default UserRoles