import { faSearch, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { GridColDef } from '@material-ui/data-grid';
import { CollapsibleTable, Form, IFormLabel, DatePicker, GroupedField, InputOnlyField, SubmitButton, Text, useAuthenticatedUser } from '@ngt/forms';
import { IFormContext, IFormSubmit } from '@ngt/forms-core';
import React, { FunctionComponent, useCallback, useContext, useMemo, useState, useEffect, useRef } from 'react';
import { Redirect, useHistory } from "react-router-dom";
import ResetButton from '../../components/Form/ResetButton';
import useReportTypes from '../../hooks/useReportTypes';
import { CSVLink } from "react-csv";
import { Data } from 'react-csv/components/CommonPropTypes';
import { DatePickerProps, DatePickerView } from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { Dropdown, Selection } from 'react-dropdown-now';
import 'react-dropdown-now/style.css';
import { UserDto } from '../../apis/dtos';
import { Routes } from '../../shared';
import { DateTime } from 'luxon';
import { Settings as LuxonSettings } from 'luxon';

//set default timezone to australia/sydney so dates at midnight appear as the correct day for users in other timezones in the world
LuxonSettings.defaultZoneName = 'Australia/Sydney';

interface IReportTypesProps {

}

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(1, 2)
    }
}));

interface ISearch {
    name?: string;
}

const initialData: ISearch = {
    name: undefined
};

const labels: IFormLabel[] = [
    {
        name: 'name',
        label: 'Name',
        detailedLabel: 'Name'
    },
    {
        name: 'dateFrom',
        label: 'Date From',
        detailedLabel: 'DateFrom'
    },
    {
        name: 'dateTo',
        label: 'Date To',
        detailedLabel: 'DateTo'
    }
];

const ReportTypes: FunctionComponent<IReportTypesProps> = () => {
    const classes = useStyles();
    const history = useHistory();

    let [data, setData] = React.useState<Data>([]);
    let [reportCode, setReportCode] = React.useState<string>('');
    let [headers, setHeaders] = React.useState<any>();
    let [csvLinkEl, setCsvLinkEl] = React.useState<any>(React.createRef());
    const [page, setPage] = React.useState<number>(0);
    const [pageSize, setPageSize] = React.useState<number>(20);
    const [name, setName] = React.useState<string | undefined>('');
    const [fileNameBase, setFileNameBase] = React.useState<string | undefined>('');
    const [fileName, setFileName] = React.useState<string | undefined>('');

    // Start variables for datepicker
    let [datefrom, setDatefrom] = useState<DateTime>(DateTime.fromJSDate(new Date()));
    let [dateto, setDateto] = useState<DateTime>(DateTime.fromJSDate(new Date()));
    const [today, setToday] = useState<DateTime>(DateTime.fromJSDate(new Date()));
    const [yearMaxNew, setYearMaxNew] = useState<number>(today.year + 78);
    const [yearMinNew, setYearMinNew] = useState<number>(today.year - 78);
    const [initialMaxDate, setInitialMaxDate] = useState<DateTime>(DateTime.fromObject({ year: yearMaxNew }));
    const [initialMinDate, setInitialMinDate] = useState<DateTime>(DateTime.fromObject({ year: yearMinNew }));

    const dateFromRef = useRef<DatePickerProps | null>(null);
    const dateToRef = useRef<DatePickerProps | null>(null);
    // End variables for datepicker

    let { data: reportTypes, total: reportTypeCount, loading: reportTypesLoading } =
        useReportTypes(name, page, pageSize);

    //console.log('reportTypes: ', reportTypes);


    const dropDownItems = [];

    // Set labels and values of dropdown list items
    if (reportTypes != undefined) {

        for (let i = 0; i < reportTypes.length; i++) {
            let objTemp = { label: reportTypes[i].name, value: reportTypes[i].code };

            dropDownItems.push(objTemp);
        }
    }

    const baseUrl = window.location.origin;

    const onDropDrownChange = (value: any) => {
        setReportCode(value.value);
        setFileName(value.value);
        setFileNameBase(value.value);        
    }

    const onGenerateReportClick = async () => {
        let localDateFrom = datefrom.setLocale('en-US');
        let localDateTo = dateto.setLocale('en-US');
        //console.log('reportCode: ', reportCode);

        if (reportCode === null || reportCode === '') {
            alert('Please select a Report Type from the dropdown list.');
        }
        else {
            // Start: set filename date
            let isdateFromHasValue = (typeof localDateFrom !== 'undefined') && (localDateFrom !== null);
            let isdateToHasValue = (typeof localDateTo !== 'undefined') && (localDateTo !== null);


            let tempFileName = fileNameBase;

            if (isdateFromHasValue || isdateToHasValue)
                tempFileName += ' -';

            if (isdateFromHasValue) {
                let tempDateStr: string = localDateFrom?.toLocaleString() ? localDateFrom?.toLocaleString() : '';
                let tempDate = new Date(tempDateStr);
                tempDateStr = `${tempDate.getDate()} ${tempDate.getMonth() + 1} ${tempDate.getFullYear()}`;
                tempFileName += ' from ' + tempDateStr;
            }

            if (isdateToHasValue) {
                let tempDateStr: string = localDateTo?.toLocaleString() ? localDateTo?.toLocaleString() : '';
                let tempDate = new Date(tempDateStr);
                tempDateStr = `${tempDate.getDate()} ${tempDate.getMonth() + 1} ${tempDate.getFullYear()}`;
                tempFileName += ' to ' + tempDateStr;
            }
            
            setFileName(tempFileName + '.csv');
            // End: set filename date

            if (reportCode == 'melanoma-thickness') {
                let url = baseUrl + '/api/report/' + reportCode + '?format=json';

                if (isdateFromHasValue)
                    url += '&dateOnOrAfter=' + localDateFrom?.toLocaleString();
                if (isdateToHasValue)
                    url += '&dateOnOrBefore=' + localDateTo?.toLocaleString();

                //console.log('params: ', params);
                //console.log('url: ', url);
                const tempData = await fetch(url)
                    .then(res => res.json());
                //console.log('tempData: ', tempData);
                setHeaders([
                    { key: 'r1' },
                    { key: 'r2' },
                    { key: 'r3' },
                    { key: 'r4' },
                    { key: 'r5' }
                ]);

                setData(tempData.data);
                setTimeout(() => {
                    csvLinkEl.current.link.click();
                });
            }
            else if (reportCode == 'melanoma-thickness-by-age') {
                let url = baseUrl + '/api/report/melanoma-thickness/true?format=json';
                
                if (isdateFromHasValue)
                    url += '&dateOnOrAfter=' + localDateFrom?.toLocaleString();
                if (isdateToHasValue)
                    url += '&dateOnOrBefore=' + localDateTo?.toLocaleString();

                //console.log('params: ', params);
                //console.log('url: ', url);
                const tempData = await fetch(url)
                    .then(res => res.json());
                //console.log('tempData: ', tempData);
                setHeaders([
                    { key: 'r1' },
                    { key: 'r2' },
                    { key: 'r3' },
                    { key: 'r4' },
                    { key: 'r5' },
                    { key: 'r6' },
                    { key: 'r7' }
                ]);

                setData(tempData.data);
                setTimeout(() => {
                    csvLinkEl.current.link.click();
                });
            }
            else if (reportCode == 'primary-melanoma-details') {
                let url = baseUrl + '/api/report/' + reportCode + '?format=json';

                if (isdateFromHasValue)
                    url += '&dateOnOrAfter=' + localDateFrom?.toLocaleString();
                if (isdateToHasValue)
                    url += '&dateOnOrBefore=' + localDateTo?.toLocaleString();

                //console.log('params: ', params);
                //console.log('url: ', url);
                const tempData = await fetch(url)
                    .then(res => res.json());
                //console.log('tempData: ', tempData);
                setHeaders([
                    { key: 'MRN' },
                    { key: 'DOB' },
                    { key: 'Gender' },
                    { key: 'DateOfReferral' },
                    { key: 'PrimarySite' },
                    { key: 'RightOrLeft' },
                    { key: 'PreviousMelanomaHistory' },
                    { key: 'Breslow' },
                    { key: 'Mitoses' },
                    { key: 'Ulceration' },
                    { key: 'UlcerationMm' },
                    { key: 'SentinelNodeBiopsy' },
                    { key: 'SentinelNodeBiopsyResult' },
                    { key: 'DateOfFirstAppointment' }
                ]);                 

                setData(tempData.data);
                setTimeout(() => {
                    csvLinkEl.current.link.click();
                });
            }
            else if (reportCode == 'recurrent-melanomas') {
                let url = baseUrl + '/api/report/' + reportCode + '?format=json';

                if (isdateFromHasValue)
                    url += '&dateOnOrAfter=' + localDateFrom?.toLocaleString();
                if (isdateToHasValue)
                    url += '&dateOnOrBefore=' + localDateTo?.toLocaleString();

                const tempData = await fetch(url)
                    .then(res => res.json());
                //console.log('tempData: ', tempData);
                setHeaders([
                    { label: 'Date', key: 'date' },
                    { label: 'Patient Name', key: 'patientName' },
                    { label: 'Local Recurrence < 3 cm', key: 'localRecurrenceGT3CM' },
                    { label: 'In Transit Recurrence', key: 'inTransitRecurrence' },
                    { label: 'Regional LNs', key: 'regionalLns' },

                    { label: 'Subcutaneous Near Regional LNs', key: 'subNearRegionalLns' },
                    { label: 'Previous LN Dissection', key: 'prevLnDissection' },
                    { label: 'Previous Sentinel Node Biopsy', key: 'prevSentiNodeBiopsy' },
                    { label: 'Skin', key: 'skin' },
                    { label: 'LNs', key: 'lns' },

                    { label: 'Lung', key: 'lung' },
                    { label: 'Brain', key: 'brain' },
                    { label: 'Liver', key: 'liver' },
                    { label: 'Intestine', key: 'intestine' },
                    { label: 'Bone', key: 'bone' },

                    { label: 'Other', key: 'other' }
                ]);

                setData(tempData.data);
                setTimeout(() => {
                    csvLinkEl.current.link.click();
                });
            }
            else if (reportCode == 'referrals-to-nmu') {
                let url = baseUrl + '/api/report/' + reportCode + '?format=json';

                if (isdateFromHasValue)
                    url += '&dateOnOrAfter=' + localDateFrom?.toLocaleString();
                if (isdateToHasValue)
                    url += '&dateOnOrBefore=' + localDateTo?.toLocaleString();

                //console.log('params: ', params);
                //console.log('url: ', url);
                const tempData = await fetch(url)
                    .then(res => res.json());
                //console.log('tempData: ', tempData);
                setHeaders([
                    { label: 'Surgery Location', key: 'name' },
                    { label: 'Count', key: 'value' }
                ]);

                setData(tempData.data);
                setTimeout(() => {
                    csvLinkEl.current.link.click();
                });
            }
            else if (reportCode == 'total-and-average')
            {
                let url = baseUrl + '/api/patients/' + reportCode + '?format=json';

                //console.log('datefrom?.toLocaleString(): ', datefrom?.toLocaleString());
                //console.log('datefrom?.toString(): ', datefrom?.toString());
                // Add date range
                if (isdateFromHasValue)
                    url += '&dateOnOrAfter=' + localDateFrom?.toLocaleString();
                if (isdateToHasValue)
                    url += '&dateOnOrBefore=' + localDateTo?.toLocaleString();

                //console.log('params: ', params);
                //console.log('url: ', url);
                const tempData = await fetch(url)
                    .then(res => res.json());
                //console.log('tempData: ', tempData);
                setHeaders([
                    { label: 'Total', key: 'count' },
                    { label: 'Total Men', key: 'countMen' },
                    { label: 'Total Women', key: 'countWomen' },
                    { label: 'Average Age', key: 'averageAge' },
                    { label: 'Men Average Age', key: 'averageAgeMen' },
                    { label: 'Women Average Age', key: 'averageAgeWomen' }
                ]);

                setData(tempData.data);
                setTimeout(() => {
                    csvLinkEl.current.link.click();
                });
            }
        }
    };

    /* START report's date filter. */
    const onDateFromChange = (event: DateTime) => {
        setDatefrom(event);
    };

    const onDateToChange = (event: DateTime) => {
        setDateto(event);
    };
    /* END report's date filter. */

    const xs = 12;
    const sm = 12;
    const md = 6;
    const lg = 4;
    const xl = 4;

    // Authenticate user
    const { data: authedUser } = useAuthenticatedUser<UserDto>();
    if (!authedUser?.isAdmin && !authedUser?.isStandard) {
        // Redirect to forbidden page
        return <Redirect to={Routes.Forbidden} />
    }

    return <>
        <style jsx>{`
            div.MuiDataGrid-cell {
                cursor: pointer;
            }
        `}</style>

        <Grid container>
            <Grid container item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={classes.container}>
                <Typography variant="h2" component="h2" gutterBottom>
                    General Report
                </Typography>
            </Grid>
        </Grid>

        <Form
            initialValues={initialData}
            labels={labels}
        >
            { /* dropdown */}
            <Grid container>
                <Grid item xs={xs} sm={sm} md={md} className={classes.container}>
                    <Dropdown
                        placeholder="Select Report Type"
                        options={dropDownItems}
                        value=""
                        onChange={onDropDrownChange}
                        onSelect={(value: any) => console.log('selected!', value)} // always fires once a selection happens even if there is no change
                        onClose={(closedBySelection: any) => console.log('closedBySelection?:', closedBySelection)}
                        onOpen={() => console.log('open!')}
                    />
                </Grid>
            </Grid>

            { /* date from */}
            <Grid container>
                <Grid item xs={xs} sm={sm} md={md} className={classes.container}>
                    <InputOnlyField id="dateFrom" name="dateFrom" component={DatePicker} inputRef={dateFromRef} maxDate={dateto ? dateto : initialMaxDate} onAccept={onDateFromChange} />
                </Grid>
            </Grid>

            { /* date to */}
            <Grid container>
                <Grid item xs={xs} sm={sm} md={md} className={classes.container}>
                    <InputOnlyField id="dateTo" name="dateTo" component={DatePicker} inputRef={dateToRef} minDate={datefrom ? datefrom : initialMinDate} onAccept={onDateToChange} />
                </Grid>
            </Grid>

            { /* generate report */}
            <Grid container>
                <Grid item xs={xs} sm={sm} md={md} className={classes.container}>
                    <Button color="primary" variant="contained" onClick={onGenerateReportClick} >Generate Report</Button>
                </Grid>
            </Grid>

        </Form>

        <CSVLink
            headers={headers}
            data={data}
            filename={fileName}
            ref={csvLinkEl}

        />
    </>
}

export default ReportTypes