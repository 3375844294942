import { ILookupFilterProps } from "@ngt/forms";
import { get } from "lodash-es";
import { MelanomaLookupItem } from "../apis/dtos";


const lookupFilterProps: ILookupFilterProps = {
    filterFunction: (formState, fieldName, lookup) =>
    {
        const value: number | null | undefined = get(formState?.initialValues, fieldName);

        return {
            propertyName: lookup?.propertyName ?? fieldName,
            items: lookup?.items?.filter(x => (x as MelanomaLookupItem).enabled === true || x.id === value) ?? []
        };
    },
    subscription: { initialValues: true }
}

export default lookupFilterProps