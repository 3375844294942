import { CssBaseline } from '@material-ui/core';
import { FormsProvider } from '@ngt/forms';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { App } from '../src/App';
import forms from './Melanoma';

ReactDOM.render(
    <FormsProvider value={forms}>
        <CssBaseline />
        <App />
    </FormsProvider>,
    document.getElementById('root')
);
