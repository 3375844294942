/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 * 
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 * 
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the context for the currently selected form.
 * --------------------------------------------------------------------------------
 */

import { FormDefinitionContext, FormDefinitionSingleResponse, FormTypeContext, FormTypeSingleResponse, IDataSingleResponse, IForm, IFormMapping, LookupCollectionResponse, LookupsContext, RouteLoading, useFormDataById, useFormDefinitionByCode, useFormTypeById, useLookupsByFormDefinitionId } from '@ngt/forms';
import * as React from 'react';
import { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { Configuration } from 'swr/dist/types';
import { IPatientForm } from '../../apis/dtos';
import PatientContext from '../../contexts/PatientContext';
import FormsContext from '../../contexts/PatientFormsContext';
import usePatientFormsByPatientId, { IDataCollectionResponse } from '../../hooks/usePatientFormsByPatientId';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

export interface IPatientFormsByPatientIdResolverProps {
    formDefinitionCode: string;
    patientId: number | null;
    resolveBeforeLoad?: boolean | null;
    formMapping?: Record<string, IFormMapping>;

    patientConfiguration?: Partial<Configuration<IDataSingleResponse<IForm>, IDataSingleResponse<IForm>>>;
    formDefinitionConfiguration?: Partial<Configuration<FormDefinitionSingleResponse, FormDefinitionSingleResponse>>;
    lookupConfiguration?: Partial<Configuration<LookupCollectionResponse, LookupCollectionResponse>>;
    formsConfiguration?: Partial<Configuration<IDataCollectionResponse<IPatientForm>, IDataCollectionResponse<IPatientForm>>>;
    formTypeConfiguration?: Partial<Configuration<FormTypeSingleResponse, FormTypeSingleResponse>>;
}
/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const PatientFormsByPatientIdResolver: React.FunctionComponent<IPatientFormsByPatientIdResolverProps> = ({
    formDefinitionCode,
    patientId,
    resolveBeforeLoad,
    formMapping,
    children,

    patientConfiguration,
    formsConfiguration,
    lookupConfiguration,
    formDefinitionConfiguration,
    formTypeConfiguration,
}) => {

    const formDefinitionContext = useFormDefinitionByCode(formDefinitionCode, formDefinitionConfiguration);

    const formDefCode = useMemo(() => formDefinitionContext?.data?.code ?? '', [formDefinitionContext?.data?.code]);

    const patientContext = useFormDataById('patient', patientId, undefined, undefined, patientConfiguration);

    const formTypeContext = useFormTypeById(formDefinitionContext?.data?.type, formTypeConfiguration);

    const formsContext = usePatientFormsByPatientId(formDefinitionCode, patientId, formsConfiguration);

    const lookupsContext = useLookupsByFormDefinitionId(formDefinitionContext?.data?.id, lookupConfiguration);

    let conditionalContexts: React.ReactNode =
        !!formMapping && formDefCode && !!formMapping[formDefCode]?.components ?
            formMapping[formDefCode]?.components :
            children;

    const loading =
        (formDefinitionCode && formDefinitionContext?.loading) ||
        (patientId && patientContext?.loading) ||
        (formDefinitionCode && patientId && formsContext?.loading) ||
        (formDefinitionContext?.data?.id && lookupsContext?.loading) ||
        (formDefinitionContext?.data?.type && formTypeContext?.loading);

    const hasError = !!(
        !patientId ||
        !formDefinitionCode ||
        formDefinitionContext?.error ||
        patientContext?.error ||
        formsContext?.error ||
        lookupsContext?.error ||
        formTypeContext?.error
    );

    if (resolveBeforeLoad && hasError) {
        return <Redirect to="/error" />
    }

    return (
        <PatientContext.Provider value={patientContext}>
            <FormTypeContext.Provider value={formTypeContext}>
                <FormDefinitionContext.Provider value={formDefinitionContext}>
                    <FormsContext.Provider value={formsContext}>
                        <LookupsContext.Provider value={lookupsContext}>
                            {
                                !resolveBeforeLoad || !loading ?
                                    conditionalContexts :
                                    <RouteLoading />
                            }
                        </LookupsContext.Provider>
                    </FormsContext.Provider>
                </FormDefinitionContext.Provider>
            </FormTypeContext.Provider>
        </PatientContext.Provider>
    );
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default PatientFormsByPatientIdResolver;
